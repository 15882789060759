import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DialogActions , DialogContent} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';

export default class CoreBaseClass extends React.Component {

    constructor(){
        super();
        this.state = {
            dialogOpen : false
        }
    }

    navigate(to) {
        //cerco la destinazione nei routes
        let route = window.routes.filter(r => r.title === to)[0];
        if(route.type === 'internalLink') {
            this.props.history.push(route.url);
        } else if(route.type === 'externalLink') {
            window.open(route.url, 'blank');
        } else if(route.type === 'htmlPage') {
            this.props.history.push('/Pagine/' + route.url);
        }
    }
    
    handleDialogOpen () {
        this.setState({
            dialogOpen:true
        })
    }
    
    handleDialogClose () {
        this.setState({
            dialogOpen:false
        })
    }            

    getDialog() {
        return (
            <Dialog disableBackdropClick={true} onClose={this.handleDialogClose} aria-labelledby="customized-dialog-title" open={this.state.dialogOpen}>
                <DialogTitle id="customized-dialog-title">
                    Ricerca delle fermate
                        <IconButton aria-label="close" edge={"end"} onClick={()=>{this.handleDialogClose()}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                    <Typography gutterBottom>
                    <ul>
                        <b>Da questo menu puoi scegliere se :</b><br></br>
                        <li>visionare l'elenco completo di tutte le fermate della Regione Calabria,
                        con possibilità di ricerca tramite testo.<br></br> </li>  
                        <li>visualizzare tutte le fermate nelle vicinanze del tuo dispositivo.<br></br> </li>
                        <li>effettuare una ricerca delle fermate vicine ad una determinata zona
                        &emsp;tramite click sulla mappa.</li>
                    </ul>  
                    </Typography>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{ this.handleDialogClose()
                            this.props.history.push('/Fermate/FermateElenco')}} 
                            color="primary" 
                            variant="contained">
                        Elenco completo fermate
                    </Button>
                    <Button onClick={()=>{ this.handleDialogClose()
                            this.props.history.push('/Fermate/FermateVicine')}}
                            color="primary"
                            variant="contained">
                        Fermate vicine
                    </Button>
                    <Button onClick={()=>{ this.handleDialogClose()
                            this.props.history.push('/Fermate/FermateRicerca')
                        }}
                            color="primary"
                            variant="contained">
                        Cerca sulla mappa
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}