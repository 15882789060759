import React from 'react';
import TopBar from '../../Utils/TopBar';
import BusIcon from '@material-ui/icons/DirectionsBus';
import DirectionWalk from '@material-ui/icons/DirectionsWalk'; 
import LoadingScreen from '../../Utils/LoadingScreen';
import { List, Typography, Button, Breadcrumbs, ListItem } from '@material-ui/core';
import { timestampToDate } from '../../Utils/utility'
import Paper from '@material-ui/core/Paper';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import DettaglioItinerarioImg from '../../Images/DettaglioItinerarioImg.jpg';
import { Map, Marker, TileLayer, Tooltip, Polyline, Routing } from 'react-leaflet';
import L from 'leaflet';
import Footer from '../../Utils/Footer';
import CookieComponent from '../../Utils/CookieComponent';
import { fetchData, fetchWay } from '../../Utils/network';

const google = window.google

export default class ItinerarioDettaglio2 extends React.Component{
    constructor(props){
        super(props)
        this.state={
            isLoading:true,
            legs:this.props.location.state.legs,
            punti:[],
            coordinates:[],
            partenza: this.props.location.state.Partenza,
            arrivo:this.props.location.state.Arrivo,
            dataPartenza:this.props.location.state.DataPartenza,
            oraPartenza:this.props.location.state.OraPartenza,
            coords : [],
            fullcoords : []
        }
    }

    componentDidMount() {                                                                           
      //setto le coordinate e i punti
      this.buildCoordinates()
      this.loadFullCoords()
      //this.test();
    }
    

      buildCoordinates(){        
        let cs = [];
        let i = 1;
        let points = [];
        this.state.legs.forEach(l => {
            // if(l.from.name != l.to.name) {
              let p = {
                from : l.from,
                to : l.to,
                mode:l.mode,
                num : i,
                icon : l.mode == 'BUS' ? <BusIcon /> : <DirectionWalk />,
                line : (l.agencyName && l.route) ? ' (' + l.agencyName + ' ' + l.route + ')' : '',
                distanceKM : (l.distance/1000).toFixed(2),
                durf : l.mode == 'BUS' ? l.from.stopId.split(':')[1] : null,
                legacyCode:l.tripId?l.tripId.split(":")[1]:null
              }              
              cs.push([p.from.lat, p.from.lon])                               
              i++;
              points.push(p);

              //20230405 carico le coordinate di ogni punto per tracciare il percorso sulla mappa
              this.state.coords.push({lat:p.from.lat, lng:p.from.lon})

            // }
             
          }
                        
        );                   
        cs.push([ points[points.length - 1].to.lat, points[points.length - 1].to.lon])      

        //20230405 carico le coordinate dell'ultimo punto per tracciare il percorso sulla mappa
        this.state.coords.push({lat:points[points.length - 1].to.lat, lng:points[points.length - 1].to.lon})

        this.setState({  
          isLoading: false,                                    
          punti: points,
          lastPoint : points[points.length - 1],
          coordinates : cs      
        });
        this.state.punti=points
        this.state.lastPoint=points[points.length - 1]
        this.state.coordinates=cs 
        this.state.isLoading=false

      }

      loadFullCoords() {
        //20230405 carico il percorso a partire dalle coordinare per disegnarlo sulla mappa
        let fullcoords = [];
        if(this.state.coords.length>1)
        fetchWay(this.state.coords).then(r => {
          if(r.error == undefined && r.result.code !=  "InvalidUrl" && r.result.routes != undefined){
            //fullcoords = r.result.routes[0].geometry.coordinates;
       
            r.result.routes[0].geometry.coordinates.forEach(c=>{
                           fullcoords.push({lat:c[1],lng:c[0]}) 

            })
      } else{
        fullcoords = this.state.coords;
      }      

        }).then(f => {
        
        this.setState({
          fullcoords:fullcoords
        })
        this.state.loadingWays = false;
        });
      }

      test() {
        setTimeout(() => {
          const container = document.getElementById('map')          
          if(container) {
            require('leaflet-routing-machine');
            let map = L.map('map')
            for(let i = 1; i < this.state.coordinates.length; i++) {
              let cs = this.state.coordinates[i - 1];
              let ce = this.state.coordinates[i];
              L.Routing.control({
                waypoints: [
                  L.latLng(cs[0], cs[1]),
                  L.latLng(ce[0], ce[1])
                ]
              }).addTo(map);
            }            
          }      
        }, 1000)
      }

    render(){      
        const pointerIcon = new L.Icon({
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            iconRetinaUrl: require('leaflet/dist/images/marker-icon.png'),
            iconAnchor: [12.5, 40],
            popupAnchor: [10, -44],
            iconSize: [25, 40],
            shadowUrl: 'leaflet/dist/images/marker-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [20, 92],
          })
        if(this.state.isLoading){
            return(
                <LoadingScreen/>
            )
        }
        else{
        return(
                <div>
                    <TopBar history={this.props.history} />
                    <div style={{backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',height:'20vh',width:'100vw',backgroundImage:"url("+DettaglioItinerarioImg+")",minHeight:280}}></div>
                    <Paper elevation={0} >
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Button onClick={()=>{this.props.history.push('/')}}>
                            Home Page
                        </Button>
                        <Button onClick={()=>{this.props.history.push('/PianificaViaggio/PianificaViaggio')}}>
                            Pianifica Viaggio
                        </Button>
                        <Button onClick={()=>{
                          window.history.back()
                           }}>
                            Elenco Itinerari
                        </Button>
                        <Typography color="grey">DETTAGLIO ITINERARIO</Typography>
                        </Breadcrumbs>
                    </Paper>
                    <div style = {{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                        <h1 style={{alignText:'center'}}>Dettaglio Itinerario</h1>
                    </div>
                    {this.state.punti ? (  
                    <List>
                        {this.state.punti.map(
                            (point) => {                
                            return (
                                <ListItem
                                button={point.mode=='BUS'?true:false}                                   
                                    onClick={(event) => { 
                                      if(point.mode=='BUS' && point.legacyCode!=null){
                                      this.setState({isLoading:true});                                    
                                        fetchData('lines/travelbylegacycode/'+point.legacyCode)
                                        .then((responseJson) => {                              
                                          if(responseJson.error != undefined){                      
                                            this.setState({error: true});                         
                                          } else { 
                                            if(responseJson.length != 0) {                    
                                              //vado nell'altra schermata
                                              this.props.history.push('/Corse/PianificaViaggioCorsa',{travel:responseJson}); 
                                            }                  
                                          }}
                                          )}
                                        }}
                                         
                                    style={{padding:15,display: 'flex',fontSize:17}}    
                                    >  
                                        <span><span style={{position: 'relative',top:5,left:5,marginRight:5}}>{point.icon}</span> {point.num +" ) " + "Partenza : " + timestampToDate(point.from.departure) +" "+ point.from.name } <span style={{position: 'relative',top:5,left:5,marginRight:5}} ><ArrowRightAlt /></span> { point.num + 1 + " ) Arrivo : " + timestampToDate(point.to.arrival) +" "+ point.to.name + " " + point.line + " (Distanza: " + point.distanceKM + " Km)" }</span>
                                     </ListItem>        
                                )             
                            })                            
                        }
                    </List>) : null }
                    <div id='map'>
                    <Map                        
                        zoom={13}
                        style={{ height: "80vh" }}
                        center={[ this.state.punti[0].from.lat, this.state.punti[0].from.lon]}
                    >                      
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                    {this.state.punti!=[]?this.state.punti.map((p,key) => (  
                    <Marker icon={pointerIcon} position={[p.from.lat, p.from.lon]}>
                        <Tooltip>
                          { " " + (p.num) + ') ' + (p.from.name)}
                        </Tooltip>
                    </Marker>              
                        
                    )
                  )
                  :null               
                }
                    {this.state.punti!=[]?
                    <Marker icon={pointerIcon} position={[this.state.lastPoint.to.lat, this.state.lastPoint.to.lon]}>
                        <Tooltip>
                          {
                            " " + (this.state.punti[this.state.punti.length-1].num+1)  + ') ' + (this.state.punti[this.state.punti.length-1].to.name)
                          }
                        </Tooltip>
                    </Marker>  :null}
 
                    <Polyline 
                        positions={this.state.fullcoords}
                        color={"blue"}
                        stroke={true}                                              
                    />
           
                  </Map>
                  </div>
                  <CookieComponent/>
                  <Footer/>
                </div> 
        )
    }
  }
}