import React from 'react';
import Topbar from '../../Utils/TopBar';
import { fetchData } from '../../Utils/network';
import { distinctElementsByName } from '../../Utils/utility';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import BusIcon from '@material-ui/icons/DirectionsBus';
import ErrorIcon from '@material-ui/icons/Error';
import { TextField, List, ListItem, ListItemIcon, ListItemText, Button, Paper, Breadcrumbs, Snackbar, SnackbarContent, Tooltip } from '@material-ui/core';
import LoadingScreen from '../../Utils/LoadingScreen';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Timeline from '@material-ui/icons/Timeline';
import LineeImg from '../../Images/LineeImg.jpg';
import Footer from '../../Utils/Footer';
import ErrorView from '../../Utils/ErrorView'
import CookieComponent from '../../Utils/CookieComponent';
import TooltipSuggestion from '../../Utils/TooltipSuggestion';
import PlacesAutocomplete from 'react-places-autocomplete';
import ProvinciaComuneIndirizzoAC from '../../Utils/ProvinciaComuneIndirizzoAC';



export default class LineeElenco extends React.Component {

  constructor() {
    super();
    this.state = {
      isLoading: true,
      operators: [],
      lines: [],
      testoRicerca: '',
      ricercaEffettuata: false,
      error: false,
      operatorId: null,
      dialogOpen: false,
      operatorName: '',
      testoRicercaLinee: '',
      linesCopy: [],
      disableButton: false,
      linesByOperator: {},
      filteredDialogOpen: false,
      operatorIdentifiers: [],
      snackbarOpen: false,
      comune : null
    };
    this.handleChange = this.handleChange.bind(this)
    this.handleLineChange = this.handleLineChange.bind(this)
  }

  componentDidMount() {
    this.loadOperators()
  }

  loadOperators() {
    this.setState({ isLoading: true });
    return fetchData('operators')
      .then((responseJson) => {
        if (responseJson.error != undefined) {
          this.setState({ isLoading: false, error: true });
          return;
        }
        let ops = this.buildOperators(responseJson);
        this.setState({
          isLoading: false,
          operators: ops,
          error: false,
          ricercaEffettuata: false,
        });

      })
      .catch((error) => {
        console.error(error);
      });
  }

  buildOperators(operators) {
    let i = 0;
    operators = distinctElementsByName(operators).sort((o1, o2) => o1.name > o2.name);
    operators.forEach(o => {
      o.children = [];
      o.text = o.name
      o.index = i;
      i++;
    });
    return operators;
  }

  buildLines(operatorName, lines, onlyOutbounds) {    
    // if (onlyOutbounds) {
    //   //filtro per ottenere esclusivamente le linee outbound            
    //   lines = lines.filter(line => line.direction == 'outbound');
    // }
    let finalLines = [];
    lines.forEach(l => {
      finalLines.push(
        {
          id: l.id,
          name: l.code,
          direction: l.direction,
          children: [],
          operatorId: l.operator.id,
          operator: operatorName,
          shortDescription: l.shortDescription,
          startFrom:l.startFrom,
          arriveTo:l.arriveTo,
          departuresArrivals:l.departuresArrivals
        }
      );
    });
    return finalLines;
  }

  fetchLines(operator) {
    let linee = []
    this.setState({ isLoading: true })
    fetchData('operators/' + operator.id + '/lines')
      .then((responseJson) => {
        if (responseJson.error != undefined) {
          this.setState({ error: true, isLoading: false });
          this.state.isLoading = false
          return;
        }
        if (responseJson.length == 0) {
          //alert("Nessuna linea trovata");
          this.setState({
            snackbarOpen: true,
            isLoading: false,
            dialogOpen: false
          })
          this.state.isLoading = false
        } else {

          linee = this.buildLines(operator.name, responseJson, true);

          this.setState({
            operatorName: operator.name,
            lines: linee,
            isLoading: false,

          })
          this.state.isLoading = false
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleClick() {
    if (this.state.dialogOpen) {
      this.setState({
        isLoading: false,
        dialogOpen: false
      });
    } else {
      this.setState({
        isLoading: false,
        dialogOpen: true
      })
    }
  };

  printLinesByOperator(lines) {
    let operatorScreen = []
    for (let operator in lines) {
      if (operator != null) {
        operatorScreen.push(
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <BusIcon style={{ marginRight: 10 }} />
              <div style={{ marginLeft: 10 }}>{this.state.operatorIdentifiers[operator] + ""}</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List>
                {this.printLines(lines[operator])}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>)
      }

    }
    return operatorScreen
  }

  printLines(operator) {
    let lines = []
    for (let line in operator) {
      lines.push(
        <ListItem
          button
          onClick={(event) => {
              let l = operator[line];
              let substrings = l.code.split("-");
              let code = substrings[0] + "-" + substrings[1];
              this.props.history.push(l.direction == 'outbound' ? '/Linee/LineeAndataDettaglio' : '/Linee/LineeRitornoDettaglio', { lineCode: code, lineId: l.id })
            }
          }
          style={{ position: 'relative', width: '100vw', margin: 5 }}
        >
          <ListItemIcon>
            <Timeline />
          </ListItemIcon>
          <ListItemText primary={operator[line].code} secondary={this.getSecondaryText(operator[line].shortDescription,operator[line].departuresArrivals)}/>

        </ListItem>
      )
    }
    return lines

  }

  onSelectComune = (comune) => {
    this.setState({
      comune : comune
    })
  }

  filtraLinee(testo) {    
    this.setState({ testoRicerca: testo });
    if (testo.trim() != '' || this.state.comune != null) {
      this.setState({
        isLoading: true,
        ricercaEffettuata: true
      });
      fetchData('lines/search/codice:' + testo + '/comune:' + (this.state.comune != null ? this.state.comune.nome : ''))
        .then((responseJson) => {
          if (responseJson.error != undefined) {
            this.setState({ error: true, isLoading: false });
            return;
          }
          if (responseJson.length == 0) {
            // alert("Nessun risultato trovato")
            this.setState({
              snackbarOpen: true,
              filteredDialogOpen: false
            })
            this.loadOperators()
          }
          else {

            let linee = responseJson
            let linesByOperator = []
            let operatorNames = []
            linee.forEach((line) => {
              if (linesByOperator[line.operator.id] == (null || undefined)) {
                linesByOperator[line.operator.id] = []
              }
              linesByOperator[line.operator.id].push(line)
              operatorNames[line.operator.id] = line.operator.name
            })
            this.setState({
              isLoading: false,
              linesByOperator: linesByOperator,
              testoRicerca: '',
              operatorIdentifiers: operatorNames,
              filteredDialogOpen: true
            })
            this.state.isLoading = false
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (testo.trim() == '' && this.state.ricercaEffettuata) {
      this.loadOperators();
    }
  }

  handleChange(e) {
    this.setState({
      testoRicerca: e.target.value,
    })

  }
  handleLineChange(e) {
    this.setState({
      testoRicercaLinee: e.target.value,
    })
  }

  getSecondaryText(shortDescription,departuresArrivals) {
    let secondaryText='Percorsi : ';
    if(shortDescription!=undefined && shortDescription!=null && shortDescription!=''){
      secondaryText+=shortDescription
    }
    if(departuresArrivals!=undefined && departuresArrivals!=null && departuresArrivals!=''){
      secondaryText+=departuresArrivals;
    }

    let replaced = 
    secondaryText
      .replaceAll(' |', ',')
      .replaceAll(' :,', ':')
      .replaceAll('-', '->');
    return replaced.substring(0, replaced.length - 1);
  }

  render() {

    const classes = {
      appBar: {
        flexDirection: 'row',
        position: 'relative',
      },
      title: {
        marginLeft: 10,
        flex: 1,
      },
      error: {
        backgroundColor: "#d32f2f",
      },
      icon: {
        size: 50,
        margin: 10,
        marginLeft: 15,
        marginRight: 15,
      },
      iconVariant: {
        opacity: 0.9,
        marginRight: 10
      },
      message: {
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center'
      },

    };

    if (this.state.error) {
      return (
        <div>
          <Topbar history={this.props.history} />
          <br />
          <br />
          <ErrorView />
        </div>
      )
    }

    if (this.state.isLoading) {
      return (
        <div><LoadingScreen /></div>
      )
    }
    else {
      return (
        <div>
          <Topbar history={this.props.history} />
          <div style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '20vh', width: '100vw', backgroundImage: "url(" + LineeImg + ")", minHeight: 280 }}></div>
          <div style={{ justifyContent: 'center', marginLeft: 20, marginTop: 5,minHeight:'99vh' }}>
            <br></br>
            <Paper elevation={0} >
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Button onClick={() => { this.props.history.push('/') }}>
                  Home Page
                        </Button>
                <Typography color="grey">ELENCO LINEE</Typography>
              </Breadcrumbs>
            </Paper>
            <ProvinciaComuneIndirizzoAC              
              setIndirizzo = {this.setIndirizzoPartenza}   
              mostraBtn = {false}           
              that = {this}
              onSelectComune = {this.onSelectComune}
            />   
            <TextField
              id="standard-name"
              type="number"
              label="Cerca una Linea"
              onChange={(event) => {
                this.handleChange(event)
              }}
              margin="normal"
              style={{ margin: 5 }}
              autoComplete={"off"}
            />
            <TooltipSuggestion message ='Inserisci il numero della linea es.211 e clicca su Cerca'/>
            <br />
            <Button variant="contained" color="primary"
              onClick={() => {
                this.filtraLinee(this.state.testoRicerca.trim())
                //   if(this.state.linesByOperator.length>0){
                //   // this.setState({
                //   //   filteredDialogOpen:true,
                //   // })
                //  }
              }}
              style={{ margin: 5 }}>
              Cerca una linea
            </Button>
            {this.state.operators ? (
              <List>
                {this.state.operators.map(
                  (operator, key) => {
                    return (
                      <ListItem
                        button
                        onClick={() => {
                          this.setState({ dialogOpen: true, operatorId: operator.id, operatorName: operator.name })
                          this.fetchLines(operator)
                        }
                        }
                        style={{ margin: 15, width:'97vw' }}
                      >
                        <ListItemIcon>
                          <BusIcon />
                        </ListItemIcon>
                        <ListItemText primary={operator.name} />

                      </ListItem>

                    )
                  })
                }
              </List>) : null}
            <Dialog id='dialog-completa' fullScreen open={this.state.dialogOpen} >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography style={{ alignSelf: 'center', alignText: 'center' }} variant="h6" className={classes.title}>
                    Linee di {this.state.operatorName}
                  </Typography>
                  <IconButton edge="end" color="inherit" onClick={() => {
                    this.setState({ dialogOpen: false, lines: [], ricercaEffettuata: false })
                    if (this.state.testoRicerca != '') {
                      this.loadOperators()
                    }
                  }} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <br></br>
              <br></br>
              <br></br>
              <div style={{ justifyContent: 'center', marginLeft: 20 }}>
              </div>
              <List>
                {this.state.lines.map(
                  (line, index) => {
                    return (
                      <ListItem
                        button
                        onClick={() => {                          
                          let substrings = line.name.split("-");
                          let code = substrings[0] + "-" + substrings[1];                          
                          this.props.history.push(line.direction == 'outbound' ? '/Linee/LineeAndataDettaglio' : '/Linee/LineeRitornoDettaglio', { lineCode: code, lineId: line.id })
                        }
                        }
                        style={{ margin: 15 }}
                      >
                        <ListItemIcon>
                          <Timeline />
                        </ListItemIcon>
                        <ListItemText primary={line.name} secondary={this.getSecondaryText(line.shortDescription,line.departuresArrivals)} />

                      </ListItem>)
                  })
                }
              </List>
            </Dialog>
            <Dialog id='dialog-filtrata' fullScreen open={this.state.filteredDialogOpen} >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography style={{ alignSelf: 'center', alignText: 'center' }} variant="h6" className={classes.title}>
                    Linee trovate
                </Typography>
                  <IconButton edge="end" color="inherit" onClick={() => {
                    //Quando chiudo ricarico tutti gli operatori
                    this.setState({ filteredDialogOpen: false, lines: [], ricercaEffettuata: false, linesByOperator: {} })
                    if (this.state.testoRicerca != '') {
                      this.loadOperators()
                    }
                  }} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <br></br>
              <br></br>
              <br></br>
              <div style={{ justifyContent: 'center', marginLeft: 20 }}>
              </div>

              {this.state.ricercaEffettuata ?
                this.printLinesByOperator(this.state.linesByOperator) : null
              }
            </Dialog>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={5000}
            onClose={() => {
              this.setState({
                snackbarOpen: false
              })
            }}
          >
            <SnackbarContent
              style={classes.error}
              aria-describedby="client-snackbar"
              message={
                <span style={classes.message}>
                  <ErrorIcon style={classes.icon} />
                  {"Nessuna linea trovata!"}
                </span>
              }
              action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={() => {
                  this.setState({
                    snackbarOpen: false
                  })
                }}>
                  <CloseIcon className={classes.icon, classes.iconVariant} />
                </IconButton>,
              ]}
            />
          </Snackbar>
          <CookieComponent/>
          <Footer />
        </div>
      );
    }
  }
}



