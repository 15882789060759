import React from 'react';
import {fetchNews} from '../../Utils/network';
import Topbar from '../../Utils/TopBar';
import "es6-promise/auto";
import LoadingScreen from '../../Utils/LoadingScreen';
import ReactHtmlParser from 'react-html-parser';
import Divider from '@material-ui/core/Divider';
import NewsImg from '../../Images/NewsImg.jpg';
import Footer from '../../Utils/Footer'
import {Paper, Breadcrumbs, Button, Typography} from '@material-ui/core';
import CookieComponent from '../../Utils/CookieComponent';
import ErrorView from '../../Utils/ErrorView'

export default class ListaNotizie extends React.Component {

    constructor(){
        super()
        this.state={
            news: [],
            isLoading: true,
            error: false  
        }
    }

    componentDidMount(){
        require('es6-promise').polyfill();
        this.loadNews();
    }


    loadNews() {
        this.setState({isLoading : true});
        let dataOdierna = new Date();
        //let dataStringa = dataOdierna.getFullYear() + '-' + (dataOdierna.getMonth()+1) + '-' + dataOdierna.getDate();
        return fetchNews('display-date/' + dataOdierna.getTime() + '/status/0/max/9999')
          .then((responseJson) => {  
            if(responseJson.error != undefined){
              this.setState({
                isLoading:false,
                error:true,
              })
              return
            }

            let s=responseJson.result;
                      
            this.setState({
              isLoading: false,
              news: s,           
              error: false,
            }) 
            
                  
          })
          .catch((error) =>{
            console.error(error);
            this.setState({
              isLoading: false,
              error:true
            })
          });
      }

    render() {
      if(this.state.error){
        return(
          <div>
            <Topbar history={this.props.history} />
            <br></br>
            <br></br>
            <br></br>
            <ErrorView/>
          </div>
        )
      }
        if(this.state.isLoading==true){
            return(
                <div>
                    <LoadingScreen/>
                </div>
            )
        }
        if(this.state.isLoading==false){
        return (
            <div>
                <Topbar history={this.props.history}/>
                <img src={NewsImg} style={{height:280,width:"100%"}}/>
                <br></br>
                <Paper elevation={0} >
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Button onClick={()=>{this.props.history.push('/')}}>
                            Home Page
                        </Button>
                        <Typography color="grey">NEWS</Typography>
                        </Breadcrumbs>
                    </Paper>
                <div style={{minHeight:'40vh'}}>
                  {this.state.news.map((avviso)=>{
                    return(
                      avviso.description.includes('#avviso')?
                      <div style={{margin:10}}>
                        <br/>
                        <b>{ReactHtmlParser(avviso.title)}</b>
                          {ReactHtmlParser(avviso.content)}
                        <br/>
                        <Divider/>
                      </div>:null
                    )
                  })}
                </div>
                <CookieComponent/>
                <Footer/>
            </div>
        );
    }}
}