import React from 'react';
import {  List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import CoreBaseClass from './CoreBaseClass';

export default class MenuLaterale extends CoreBaseClass {
    
    constructor(props){
        super(props);        
    }    

    render(){
        return(
                <div>
                    {this.getDialog()}
                    <List>
                        {
                            window.routes.map(r =>
                                <ListItem 
                                    button
                                    onClick={() => {
                                            if(r.title.includes('Fermate'))                                             
                                                    this.handleDialogOpen();
                                            else
                                                this.navigate(r.title);
                                        }
                                    }
                                    style={{margin:5}}
                                >
                                    <ListItemIcon> 
                                        <Icon>{r.icon}</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={r.title} />
                                </ListItem>
                            )
                        }                        
                    </List>                    
                </div>
        )
    }
}