import React from 'react';
import Topbar from '../../Utils/TopBar';
import { timestampToDate, stopTimeToTodayDate, sumMinutes } from '../../Utils/utility';
import LoadingScreen from '../../Utils/LoadingScreen';
import L from 'leaflet';
import { Map, Marker, TileLayer, Tooltip, Polyline } from 'react-leaflet';
import Footer from '../../Utils/Footer';
import { Button, Paper , Breadcrumbs,Typography, IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import CookieComponent from '../../Utils/CookieComponent';

import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { fetchWay } from '../../Utils/network';

export default class VeicoliInMovimento extends React.Component {

    constructor(props){
        super(props)
        let vs=this.props.location.state.vehicleStatus        
        let travel=this.props.location.state.travel
        this.state={
          isLoading:true,
          isSetLastPassedDelay : false,
          vehicleStatus:vs,
          travel:travel,
          location:vs!=null?{lat:vs.lastPacket.packet.location.latitude, lng:vs.lastPacket.packet.location.longitude }  : null ,
          packetDate:vs!=null?timestampToDate(new Date(vs.lastPacket.packet.timestamp*1000)):null,
          noTrackingMessage : false,//vs == null
          coords:[],
          fullcoords:[],
          loadingWays:true,

        }
      }

      componentDidMount(){
        this.calculateDepartures()
      }

      getMadeOrScheduledDeparture(stop, programmed) {  
        let isLast = stop.num == this.state.travel.stops[this.state.travel.stops.length - 1].num;        
        let label = stop.num == 1 ? 'Partenza' : isLast ? 'Arrivo' : 'Transito';
        label += ' ';
        if(programmed) {
            label += 'Programmat';
        } else if(stop.notPassed){
            label += 'Previst';          
        } else {       
            label += 'Effettuat';
        }
        label += stop.num == 1 ? 'a' : 'o';          
        return label;
    }

   


    calculateDelay(stop) {
        //calcolo il ritardo
        let diff = stop.timestamp - (stop.dateTime.getTime() / 1000);                              
        stop.delay = diff > 0;  

        //trasformo diff in positivo
        diff = diff < 0 ? diff * -1 : diff;
        //trasformo diff da secondi ad ore
        stop.hours = Math.floor(diff / 3600);         
        //trasformo diff da secondi a minuti
        stop.minutes = Math.floor((diff - (stop.hours * 3600))/ 60);                                        
    }

    getDepartureText(stop, vs) {  
      //debugger;

      if(stop.notPassed && (new Date().getTime()/1000 - stop.dateTime.getTime()/1000 ) > 3600) {
        stop.timestampDecoded  = 'Errore sulla corsa';
      }
      if( (stop.timestamp + 3600) < stop.dateTime.getTime()/1000 ) {          
       return stop.timestampDecoded + '(Errore sulla fermata)';
      //  debugger;
      }
      if( (stop.timestamp + 60) < stop.dateTime.getTime()/1000 ) {          
        return stop.timestampDecoded + ' (in anticipo)';
      }
      if( (stop.hours == 0 && stop.minutes == 0) || !stop.delay) {
          return timestampToDate(stop.dateTime.getTime()) + (vs == null ? '' : ' (in orario)');
      }
      let ris = stop.timestampDecoded + ' (';        
      if(stop.hours == 1) {
          ris += stop.hours + ' ora';
      } else if(stop.hours > 0) {
          ris += stop.hours + ' ore';
      }
      if(stop.minutes > 0) {            
          if(stop.hours > 0) {
              ris += ' e ';
          }
          ris += stop.minutes;
          if(stop.minutes > 1) {
              ris += ' minuti';
          } else {
              ris += ' minuto';
          }        
      }        
      ris += ' di ritardo)';

      return ris;
    }


    getDepartureTime(stop, vs) {             
        if(!stop.timestamp) {             
            let stops = this.state.travel.stops;
            //verifico che non ci siano pacchetti relativi ad una fermata successiva
            if(stops.filter(s => s.num > stop.num && s.timestamp).length > 0) {
                stop.stimato=true;                
               // let previousStop = this.state.travel.stops[stop.num - 2];
                // return timestampToDate(stop.dateTime.getTime());
            }
            let now = new Date().getTime()/1000;
            let timestamp;

            //recupero il ritardo accumulato sulla precedente fermata
            let previousStop = this.state.travel.stops[stop.num - 2];
            let currentStopDateTimeCalc = stop.dateTime.getTime()/1000
            if(previousStop)
              currentStopDateTimeCalc += previousStop.hours * 3600 + previousStop.minutes * 60;

            if(stop.notPassed && currentStopDateTimeCalc < now && !this.state.isSetLastPassedDelay) {            
              //i pacchetti si interrompono ad una fermata precedente quindi calcolo il ritardo fino al timestamp attuale                           
              stop.timestamp = now;
              stop.delay = true;
              this.calculateDelay(stop);
              let diff = stop.timestamp - (stop.dateTime.getTime() / 1000);
              timestamp = (stop.dateTime.getTime() / 1000) + diff;
              this.state.isSetLastPassedDelay = true;
            } else {
              let lastPassedStop = stops[0];
              for(let i = 0; i < stops.length; i++) {
                  if(stops[i].timestamp) lastPassedStop = stops[i];
                  if(i + 1 < stops.length && stops[i + 1].durfCode == stop.durfCode) break;
              }
              //assegno alla fermata attuale il ritardo della precedente
              stop.hours = lastPassedStop.hours;
              stop.minutes = lastPassedStop.minutes;
              stop.delay = lastPassedStop.delay;                                
              timestamp = stop.dateTime.getTime() / 1000;
              if(stop.delay) {
                  //sommo perchè in ritardo
                  timestamp += (stop.hours * 3600);
                  timestamp += (stop.minutes * 60);
              } else {
                  //sottraggo perchè in anticipo
                  timestamp -= (stop.hours * 3600);
                  timestamp -= (stop.minutes * 60);
              }
            }                        
            stop.timestamp = timestamp;
            stop.timestampDecoded = timestampToDate(timestamp);                         
        } else {            
            this.calculateDelay(stop);
        }                       
        return this.getDepartureText(stop, vs);
        
    }

    getOpacity(stop) {        
        return stop.notPassed ? 'grey' : 'blue';
    }

    calculateDepartures() {  
        let vehicleStatus = this.state.vehicleStatus;         
        let num = 1;
        let now = new Date().getTime();
        this.state.travel.stops.forEach(s => {
            let todayTime = stopTimeToTodayDate(s.time,this.state.travel.stops[0].time,this.state.travel.stops[this.state.travel.stops.length-1].time);

            s.num = num++;
            s.dateTime = todayTime;    
            //considero che il pullman è passato dalla fermata selezionata in base al timestamp attuale e all'orario della fermata
            s.notPassed = todayTime.getTime() > now;            
        });
        
        let precedenti = this.state.travel.stops.filter(s=>!s.notPassed);
        let lastPassedStopStimata=precedenti.length == 0 ? null : precedenti[precedenti.length - 1] 
        let lastPassedStop = null;
        let cs = this.state.travel.stops.filter(s => !s.notPassed).map(s=> {return {lat: s.latitude, lng:s.longitude}});

        //controllo se ci sono pacchetti
        if(vehicleStatus != null){          
            let packets = vehicleStatus.packets
                //prendo solo i pacchetti relativi ad una fermata che sia anche contenuta nell'elenco fermate della corsa
                .filter(p => p.packet.parameter && p.packet.parameter != null && p.packet.parameter != 0 && this.state.travel.stops.filter(s => s.durfCode == p.packet.parameter).length > 0)                
                //ordino i pacchetti per timestamp crescente
                .sort((p1, p2) => p1.packet.timestamp - p2.packet.timestamp);                  
            if(packets.length > 0) {
              //recupero il durf dell'ultima fermata registrata
              lastPassedStop = packets[packets.length - 1].packet.parameter!=undefined? packets[packets.length - 1].packet.parameter:[];  
              //segno la fermata corrispondente a lastPassedStop come passata
              let lps = this.state.travel.stops.filter(s => s.durfCode == lastPassedStop)[0];
              if(lps) {
                lps.notPassed = false;                        
                //segno le fermate successive a lastPassedStop come non passate
                this.state.travel.stops.filter(s => s.num > lps.num).forEach(s => s.notPassed = true);
              }              
              // //aggiungo le coordinate della posizione del veicolo
              cs.push({lat: this.state.location.lat , lng : this.state.location.lng});    
            } else {
              //entro qui quando ho il vs ma non passa dalle fermata della corsa              
              vehicleStatus = null;             
            }               
        }    
        //costruisco gli elementi da visualizzare nella tabella
        let tableData = [];        
        this.state.travel.stops.forEach(s => {     
            let stimato = !s.timestamp && this.state.travel.stops.filter(stop => stop.num > s.num && stop.timestamp).length > 0;                       
            let row = [];                  
            //prima colonna con le informazioni inerenti alla fermata
            row.push(
              {
                name:s.address,
                number:"Numero: " +s.durfCode,
                scheduledTime: this.getMadeOrScheduledDeparture(s, true) + " : " +  s.time,
                departureTime: this.isInTravelRange() ? this.getMadeOrScheduledDeparture(s, false) + ((stimato || vehicleStatus == null) && s.dateTime.getTime() < new Date().getTime() ? " (dato stimato)" : "") + " : " + this.getDepartureTime(s, vehicleStatus) : null,
                color:this.getSvgColor(s),
                stimato : stimato,
                icon:this.getStopIcon(s, lastPassedStop, lastPassedStopStimata)
              }
                
            );           
            tableData.push(row);
            
            if(!s.notPassed){
                              
              this.state.coords.push({lat:s.latitude, lng:s.longitude})
            
            };

    



            
            

        }); 
        if( this.isInTravelRange() && this.state.location!=null)
        this.state.coords.push({lat:this.state.location.lat, lng: this.state.location.lng});

        let fullcoords = [];
        if(this.state.coords.length>1)
        fetchWay(this.state.coords).then(r => {
          if(r.error == undefined && r.result.code !=  "InvalidUrl" && r.result.routes != undefined){
            //fullcoords = r.result.routes[0].geometry.coordinates;
       
            r.result.routes[0].geometry.coordinates.forEach(c=>{
                           fullcoords.push({lat:c[1],lng:c[0]}) 

            })

            //   r.result.routes[0].legs.forEach(leg => {
          
        //     leg.steps.forEach( step => {
        //     step.intersections.forEach(intersection=>{
              
        //     fullcoords.push({lat:intersection.location[1],lng:intersection.location[0]})});  
        //   })
        // });
      } else{
        fullcoords = this.state.coords;
      }      

        }).then(f => {
        
        this.setState({
          fullcoords:fullcoords
        })
        this.state.loadingWays = false;
        });
        
        
        this.setState({                  
            cordinates: cs,
            tableData: tableData,
           vehicleStatus : vehicleStatus,
            location : vehicleStatus == null && lastPassedStopStimata != null? {lat:lastPassedStopStimata.latitude, lng: lastPassedStopStimata.longitude} : this.state.location,
            isLoading : false,

        });

    }
   

      getPointerIcon(key){
        const pointerIcon = new L.Icon({
          iconUrl: require('../../Images/coreMarkers/iconset_'+(key+1)+'.png'),
          iconRetinaUrl: require('../../Images/coreMarkers/iconset_'+(key+1)+'.png'),
          iconAnchor: [12.5, 40],
          popupAnchor: [10, -44],
          iconSize: [40, 40],
          shadowUrl: 'leaflet/dist/images/marker-shadow.png',
          shadowSize: [68, 95],
          shadowAnchor: [20, 92],
        })

        return pointerIcon
      }

      getSvgColor(stop) {
        return this.isInTravelRange() && !stop.notPassed ? "blue" : "grey";
     }

      isInTravelRange() {
        let timestampNow = new Date().getTime();
        let firstStop = this.state.travel.stops[0].dateTime.getTime();
        let lastStop = this.state.travel.stops[this.state.travel.stops.length - 1].dateTime.getTime();
        return !(this.state.vehicleStatus == null && (timestampNow < firstStop || timestampNow > lastStop));            
     }

      getLineOrCircleColor(scheduledTime){
        let ora=new Date()
        let oraPassaggio=new Date()
        oraPassaggio.setHours(scheduledTime.split(":")[1])
        oraPassaggio.setMinutes(scheduledTime.split(":")[2])
        if(ora>oraPassaggio){
          return 'blue'
        }
        else{
          return 'grey'
        }
      }

      getStopIcon(item, lastPassedStop, lastPassedStopStimata) {             
        let source="false";                        
        //verifico se non esistono pacchetti o l'ultima fermata registrata non è presente tra l'elenco fermate della corsa
        if(lastPassedStopStimata != null && (this.state.vehicleStatus == null || this.state.travel.stops.filter(s => s.durfCode == lastPassedStop).length == 0)) {
            //posizioni il bus sull' ultima fermata precedente all'ora attuale
            if(this.state.travel.stops[0].dateTime.getTime() > new Date().getTime()) {
                //la corsa non è ancora cominciata
                source = "false";
            } else {
                if(item.durfCode == lastPassedStopStimata.durfCode && item.time == lastPassedStopStimata.time) {
                    source = "true";
                }
            }        
        } else {            
            if(item.durfCode == lastPassedStop) {
                source = "true";
            } else {
                source = "false";
            }
        }
        return source
      }


      render(){
        const coords = [];
        //let fullcoords = this.state.fullcoords 


        const classes = {
          error: {
            backgroundColor: "#d32f2f",
          },
          messaggio: {
            backgroundColor: '#1976d2'
          },
          icon: {
            size: 50,
            margin: 10,
            marginLeft: 15,
            marginRight: 15,
          },
          iconVariant: {
            opacity: 0.9,
            marginRight: 10
          },
          message: {
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center'
          },
    
        };
        const pointerIcon = new L.Icon({
            iconUrl: require('../../Images/leafletMarkers/marker-icon-red.png'),
            iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon-red.png'),
            iconAnchor: [12.5, 40],
            popupAnchor: [10, -44],
            iconSize: [25, 40],
            shadowUrl: 'leaflet/dist/images/marker-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [20, 92],
          })
          const myPointerIcon = new L.Icon({
            iconUrl: require('../../Images/leafletMarkers/marker-icon-red-bus.png'),
            iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon-red-bus.png'),
            iconAnchor: [12.5, 40],
            popupAnchor: [10, -44],
            iconSize: [25, 40],
            shadowUrl: 'leaflet/dist/images/marker-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [20, 92],
          })
        const p1=L.latLng(  37.522050, 14.767147);
        const p2=L.latLng(40.505361, 17.903747);
        const mapBounds=L.latLngBounds(p2,p1);
        let cords = [];
        if(this.state.isLoading) {
            return(
            <LoadingScreen/>
            )
          }
          else{
          return(
               <div>
                  <Topbar history={this.props.history} />
                  <br></br>
                  <Paper elevation={0} >
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Button onClick={()=>{this.props.history.push('/')}}>
                            Home Page
                        </Button>
                        <Button onClick={()=>{this.props.history.push('/Linee/LineeElenco')}}>
                            Elenco Linee
                        </Button>
                        <Button onClick={()=>{window.history.go(-2)}}>
                            Dettaglio Linea
                        </Button>
                        <Button onClick={()=>{window.history.go(-1)}}>
                            Dettaglio Corsa
                        </Button>
                        <Typography color="grey">TRACCIAMENTO VEICOLO</Typography>
                        </Breadcrumbs>
                  </Paper>
                  <br></br>
                  <p>Tracciamento corsa {this.state.travel.code}-{this.state.travel.legacyCode} - {this.state.travel.operatorName}</p>
                  <div style={{width:'100%',overflow:'scroll'}}>
                  <div style={{height:'10%',width:'max-content'}}>
                  {
                    this.state.tableData.map((td,key)=>{
                        return( 
                        <svg height="120" width="500" style={{alignSelf:'center'}}>
                          <circle cx="240" cy="18" r="14" fill={td[0].color} />
                          <line x1="0" y1="18" x2="251" y2="18" stroke={td[0].color} strokeWidth={10} />
                          <line x1="250" y1="18" x2="500" y2="18" stroke={td[0].color} strokeWidth={10} />
                          <text className="heavy" x="10" y="55" fill="black" style={{fontWeight:700}}>{key+1})</text>
                          <text x="35" y="55" fill="black" style={{fontFamily:'arial',fontSize:13}}>{td[0].name}</text>
                          <text x="10" y="70" fill="black" style={{fontFamily:'arial',fontSize:13}}>{td[0].scheduledTime}</text>
                          <text x="10" y="86" fill="black" style={{fontFamily:'arial',fontSize:13}}> {td[0].stimato || this.state.vehicleStatus == null ? <title>Per questa fermata non sono presenti dati realtime</title> : null} {td[0].departureTime}</text>
                          {td[0].icon=="true"?<path transform="translate(228 6)" d= "M4 16c0 .88.39 1.67 1 2.22V20c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h8v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1.78c.61-.55 1-1.34 1-2.22V6c0-3.5-3.58-4-8-4s-8 .5-8 4v10zm3.5 1c-.83 0-1.5-.67-1.5-1.5S6.67 14 7.5 14s1.5.67 1.5 1.5S8.33 17 7.5 17zm9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-6H6V6h12v5z" stroke="#999999" fill="white" ></path>:null}
                        </svg>
                        )
                    })}

                  </div>
                  </div>
                  <br></br>
                    { 
                    <Map style={{ height: "85vh" }} center={this.isInTravelRange() && this.state.location!=null ? [this.state.location.lat,this.state.location.lng] : [this.state.travel.stops[0].latitude , this.state.travel.stops[0].longitude]} maxBounds={mapBounds} zoom={14}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            />
                            
                        {
                        this.state.travel.stops?this.state.travel.stops.map(
                            (stop , key) => {

                            
                            let index=this.state.tableData[key]
                            



                            return (
                            <Marker icon = {this.getPointerIcon(key)} position={[stop.latitude, stop.longitude]}>
                            <Tooltip><div>{index[0].name}</div> 
                            <div>{index[0].scheduledTime}</div>
                            <div>{index[0].departureTime}</div>
                            </Tooltip>
                            </Marker>
                            )
                        
                      
                        }):null}

                                            
                         {this.isInTravelRange() && this.state.location!=null ?
                         <Marker icon={myPointerIcon} position={ [this.state.location.lat, this.state.location.lng] }>
                            <Tooltip>{this.state.vehicleStatus == null ? 'Posizione stimata del veicolo ' : 'Ultima posizione del veicolo registrata ' + this.state.packetDate}</Tooltip>
                         </Marker>
                        

                         
                         :null}

                        <Polyline 
                          positions={this.state.fullcoords}
                          color={"blue"}
                          stroke={true}
                        
                        
                        />

                      </Map> }
                    <CookieComponent/>
                    
                    <Footer/>
                      <Snackbar
                        id="snackbar-alert"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        open = {this.state.noTrackingMessage}
                        autoHideDuration = {5000}
                        onClose={() => {
                          this.setState({
                            noTrackingMessage: false
                          })
                        }}
                      >
                        <SnackbarContent
                          style={classes.error}
                          aria-describedby="client-snackbar"
                          message={
                            <span style={classes.message}>
                              <ErrorIcon style={classes.icon} />
                              {"Il tracciamento non è basato su dati in tempo reale in quanto"} <br/>
                              {"non risultano informazioni realtime relative alla corsa selezionata"}
                            </span>
                          }
                          action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={() => {
                              this.setState({
                                noTrackingMessage: false
                              })
                            }}>
                              <CloseIcon className={classes.icon, classes.iconVariant} />
                            </IconButton>,
                          ]}
                        />
                      </Snackbar>            
                </div>
          )
      }
    }
}
