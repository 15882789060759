import React from 'react';
import Topbar from '../../Utils/TopBar';
import DettaglioLinee from '../../Utils/DettaglioLinee';




export default class LineeAndataDettaglio extends React.Component {

    constructor(props){
        super(props)
        this.state={
            isLoading:true,
        }
    }

    render(){

        return(
            <div>
                <DettaglioLinee history={this.props.history} lineCode={this.props.location.state.lineCode} lineId = {this.props.location.state.lineId} isOutBound = {true} navigateTo = '/Linee/LineeRitornoDettaglio'  detail = '/Corse/CorseDettaglioLA'/> 
            </div>
        )
        
    }

}