import React from 'react';
import LogoImage from "../src/Images/logoblu.png"

export default class Manutenzione extends React.Component {


    constructor(){
        super()
        this.state={ }
    }


    render() {
        return (
            <div class="maintenanceMode">
                <img src={LogoImage}/>
                <h1>Portale in manutenzione</h1>
                <span>{window.configurations.maintenanceMode.message} </span>
            </div>
            
        );
    }
    }
