import React from 'react';
import {fetchData} from '../Utils/network';
import { ListItem, ListItemIcon, Button,ListItemText, Paper, Breadcrumbs,Typography,Icon,InputAdornment, Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import BusIcon from '@material-ui/icons/DirectionsBus';
import AccessTime from '@material-ui/icons/AccessTime';
import LoadingScreen from './LoadingScreen';
import {travelsFilter, formatDate, yyyyMMddToddMMyyyy} from './utility';
import { MuiPickersUtilsProvider ,  KeyboardTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LineeDettaglioImg from '../Images/LineeDettaglioImg.jpg';
import {formatDateTime} from '../Utils/utility';

import Topbar from '../Utils/TopBar';
import Footer from './Footer'
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import Warning from '@material-ui/icons/Warning';
import {it} from 'date-fns/locale';


export default class DettaglioLinee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading : true,
          lineId : this.props.lineId,            
          hour : null,
          date : null,
          snackbarOpen:false,
        }    
      }

      componentDidMount(){
        this.caricaLinea();
      }

      handleDatePicked = (date) => {    
        let oraSelezionata = new Date(date);
        oraSelezionata.setDate('1');
        oraSelezionata.setMonth('0');
        oraSelezionata.setFullYear('1970')
        this.filtraCorse(oraSelezionata);
      }

      filtraCorse(oraSelezionata) {
        //filtro a seconda dell'ora di partenza
        let listaFiltrata = travelsFilter(oraSelezionata, this.state.travels);    
        if(listaFiltrata.length == 0) {
          //alert('Nessuna corsa trovata');
        }
        this.setState({filteredTravels:listaFiltrata, hour: oraSelezionata})
      }

      showMessage(travel){
        if(travel.message!=null){
          if(travel.messageExpire==null || new Date(travel.messageExpire)>new Date()){
            if(travel.messageExpire!=null){
              return <div style={{textAlign:'center'}}><Warning style={{color:'orange'}}/> Corsa sospesa fino al {yyyyMMddToddMMyyyy(travel.messageExpire)}<Warning style={{color:'orange'}}/> <br></br> {travel.message} </div>
            } else return <div style={{textAlign:'center'}} ><Warning style={{color:'orange'}}/> Corsa sospesa <Warning style={{color:'orange'}}/> <br></br> {travel.message}</div>
    
          }
        }
      }

      caricaLinea() {    
        this.setState({isLoading : true});
        fetchData('lines/' + this.state.lineId)
          .then((responseJson) => {          
            if(responseJson.error != undefined){
              this.setState({isLoading: false, error: true});
              return;
            }                                  
            //setto le coordinate e le fermate
            let cs = [];
            let filter = this.props.isOutBound ? 'outbound' : 'inbound';
            //let list = responseJson.travels.filter(t => t.travelDirection == filter); 
            let list = responseJson.travels.filter(t => t.travelDirection == filter && t.stops.length > 0); 
            let fermate = list.length == 0 ? [] : list[0].stops;
            fermate.forEach(f => {
              f.num = "" + (parseInt(f.id.split("-")[1]) + 1);
              cs.push({latitude: f.latitude, longitude: f.longitude})
            })        
            this.setState({                          
              line: responseJson,
              stops: fermate,
              cordinates: cs,
              travels: list,
              filteredTravels : list,
              error : false
            });                    
          })             
          .catch((error) =>{
            console.error(error);
          })
          .then( (x) => { 
            if(this.state.error) return;                   
            //verifico se esiste la linea inbound corrispondente      
            fetchData('lines/returnline/' + this.state.filteredTravels[0].macroId)                            
            .then((responseJson) => {   
              if(responseJson.error != undefined){
                this.setState({isLoading: false, error: true});
                return;
              }                              
              let inbound = null;
              if(responseJson.travels != undefined && responseJson.travels.length > 0){
                  inbound = responseJson;
              }                 
              this.setState({      
                  isLoading: false,    
                  inboundLine: inbound,
                  error : false
              });
            })             
            .catch((error) =>{
              console.error(error);
            })            
          });
      }

      render(){
        const classes = {
          error: {
            backgroundColor: "#d32f2f",
          },
          icon: {
            size: 50,
            margin:10,
            marginLeft:15,
            marginRight:15,
          },
          iconVariant: {
            opacity: 0.9,
            marginRight:10
          },
          message: {
            fontSize:16,
            display: 'flex',
            alignItems: 'center',
            alignSelf:'center'
          },
    
        };
          if(this.state.isLoading){
              return(
                  <div>
                      <LoadingScreen/>
                  </div>
              )
          }
          else{
          return(
              <div>
                <Topbar history={this.props.history} />
                <div style={{backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',height:'20vh',width:'100vw',backgroundImage:"url("+LineeDettaglioImg+")",minHeight:280}}></div>
                <br></br>
              <Paper elevation={0} >
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Button onClick={() => { this.props.history.push('/') }}>
                    Home Page
                        </Button>
                  <Button onClick={() => { this.props.history.location.pathname == "/Linee/LineeAndataDettaglio" || this.props.history.location.pathname == "/Linee/LineeRitornoDettaglio" ? this.props.history.push('/Linee/LineeElenco') : this.props.history.push('/Fermate/FermateElenco') }}>
                    {this.props.history.location.pathname == "/Linee/LineeAndataDettaglio" || this.props.history.location.pathname == "/Linee/LineeRitornoDettaglio" ? 'Elenco Linee' : 'Elenco Fermate'}
                  </Button>
                  {this.props.history.location.pathname == "/Fermate/FermateLineaAndataDettaglio" || this.props.history.location.pathname == "/Fermate/FermateLineaRitornoDettaglio" ? <Button onClick={() => { window.history.back() }}>
                    Dettaglio Fermata
                        </Button> : null}
                  <Typography color="grey">DETTAGLIO LINEA</Typography>
                </Breadcrumbs>
              </Paper>
              <Typography style={ { margin:15, fontSize:18, fontStyle:'italic' } }>{ "Linea: " + this.state.line.code + "  " + "Operatore: "+this.state.line.operator.name}</Typography>                                     
                  <div>
                    <Button
                        disabled = {!this.state.inboundLine || this.state.inboundLine == null}
                        style={{margin:10}}
                        variant="contained" color="primary"
                        onClick = { (event) => {                             
                            this.props.history.push(this.props.navigateTo, {lineId : this.state.inboundLine.id, lineCode:this.props.history.location.state.lineCode, lineName : this.state.inboundLine.code})                            
                          }
                        }                        
                      >{'Mostra la Linea di ' + (this.props.isOutBound ? 'ritorno' : 'andata')}
                    </Button>
                  </div>                    
                
                  <div>
                    {this.state.hour !== null?
                    <Button
                        style={{margin:15}}
                        variant="contained" color="primary"
                        onClick = { (event) => {                           
                            if(this.state.hour==null){
                              this.handleDatePicked(this.state.date)  
                            }
                            else{
                                this.setState({hour: null, filteredTravels: this.state.travels, date:null})
                            }      
                         }
                        }
                    >{'Rimuovi filtro'}
                    </Button>:null}
                    {/* <div>
                        <MuiPickersUtilsProvider style={{cursor:'pointer'}} utils={DateFnsUtils} locale={it}>
                            <KeyboardTimePicker
                                style={{margin:15,cursor:'pointer',width:200}}
                                margin="normal"
                                label="Seleziona l'orario"
                                value={this.state.date}
                                ampm={false}
                                keyboardIcon={<AccessTime/>}
                                InputLabelProps={{
                                  shrink: true,
                                }}

                                onChange={()=>{console.log("")}}
                                onAccept={(date)=>{this.setState({
                                  date:date
                                })
                                this.state.date=date
                                if(this.state.hour==null){
                                  this.handleDatePicked(this.state.date)  
                                }
                                else{
                                    this.setState({hour: null, filteredTravels: this.state.travels})
                                }      
                             }
                                
                              }
                                 
                            />
                        </MuiPickersUtilsProvider>
                    </div> */}
                        {this.props.history.location.state.lineCode?this.state.filteredTravels.map((item)=>{
                          if(item.code.trim().includes(this.props.history.location.state.lineCode.trim())){ 
                                return(
                                    <ListItem
                                        button          
                                        onClick={(event) => {
                                          this.props.history.push(this.props.detail, {travel : item}) 
                                                  
                                                }
                                            }
                                        style={{margin:15,width:'99vw'}}    
                                        >  
                                        <ListItemIcon> 
                                            <BusIcon />
                                        </ListItemIcon>
                                        <ListItemText primary = { 'Corsa Numero : ' + item.code +"-"+ item.legacyCode } 
                                                      secondary={item.stops[0].address + ' ' + item.stops[0].time + '-' + item.stops[item.stops.length - 1].address + ' ' + item.stops[item.stops.length - 1].time}
                                                    /> 
                                                    {this.showMessage(item)}      
                                          
                                    </ListItem>
                                )}
                            }
                            
                        ):null}
                    </div>
                    <Snackbar
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      open={this.state.snackbarOpen}
                      autoHideDuration={5000}
                      onClose={()=>{
                        this.setState({
                          snackbarOpen:false
                        })
                      }}
                    >
                      <SnackbarContent
                        style={classes.error}
                        aria-describedby="client-snackbar"
                        message={
                          <span style={classes.message}>
                            <ErrorIcon style={classes.icon} />
                            {"Nessuna corsa trovata"}
                          </span>
                        }
                        action={[
                          <IconButton key="close" aria-label="close" color="inherit" onClick={()=>{this.setState({
                            snackbarOpen:false
                          })}}>
                            <CloseIcon className={classes.icon,classes.iconVariant} />
                          </IconButton>,
                        ]}
                      />
                    </Snackbar>
                    <Footer/>
              </div>
          )}
      }
}