import React from 'react';
import Topbar from '../../Utils/TopBar';
import {fetchData} from '../../Utils/network';
import { TextField, List, ListItem, ListItemIcon, ListItemText, Button, Paper, Breadcrumbs, Typography,
Snackbar, SnackbarContent, IconButton  } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import LoadingScreen from '../../Utils/LoadingScreen';
import LocationOn from '@material-ui/icons/LocationOn';
import FermateImg from '../../Images/FermateImg.jpg';
import Footer from '../../Utils/Footer'
import ErrorView from '../../Utils/ErrorView'
import CookieComponent from '../../Utils/CookieComponent';



export default class FermateElenco extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
          stops: [],   
          text: '',
          lastPage: 1,
          error: false,
          snackbarOpen:false,
          ricercaEffettuata:false,

        };
    }

    caricaFermate() {   
        //carico la prossima pagina e aggiungo il risultato in coda alla lista delle fermate già caricate
        fetchData('stops/pages/' + this.state.lastPage)
          .then((responseJson) => {        
            if(responseJson.error != undefined){
              this.setState({isLoading: false, error: true});
              return;
            } 
            let ss = this.state.stops;        
            responseJson.forEach(s => {  
              ss.push(s);
            });           
            this.setState(()=>({
              isLoading : false,
              stops : ss,
              error : false,    
            }));
          })
          .catch((error) => {
            console.error(error);
          });
        }


      // isBottom(el) {
      //   return el.getBoundingClientRect().bottom + window.innerHeight/48 <= window.innerHeight;
      // }    

    componentDidMount(){
        this.caricaFermate();
        // document.addEventListener('scroll', this.trackScrolling);
    } 
    

    componentWillUnmount(){
      this.setState({stops: []})
      // document.removeEventListener('scroll', this.trackScrolling);
    }

    // trackScrolling = () => {
    //   const wrappedElement = document.getElementById('fermate');
    //   if (this.isBottom(wrappedElement) && this.state.ricercaEffettuata==false) {
    //      this.setState((prevState)=>({
    //       lastPage : prevState.lastPage + 1,
    //     }))
    //     this.caricaFermate()  
    //   }
    // };



    filtraFermate(testo) {
      this.setState({isLoading: true , stops: []});
      if(testo.trim() != '') {    
        fetchData('stops/search/' + testo)
          .then((responseJson) => { 
            if(responseJson.error != undefined){
              this.setState({error: true, isLoading: false});
              return;
            }           
            this.setState({
              isLoading: false,
              stops: responseJson,           
              error: false,
              ricercaEffettuata:true,
              text:'',
            });
            // if(responseJson.length == 0) {
            //   //alert("Nessun risultato trovato") 
            //   this.setState({
            //     text:'',
            //     snackbarOpen:true
            //   })
            // }
          })
          .catch((error) => {
            console.error(error);
          });      
      } 
     }
     
     handleChange(e){
      this.setState({
        text: e.target.value,
      })

    }

    render() {
      const classes = {
        error: {
          backgroundColor: "#d32f2f",
        },
        icon: {
          size: 50,
          margin:10,
          marginLeft:15,
          marginRight:15,
        },
        iconVariant: {
          opacity: 0.9,
          marginRight:10
        },
        message: {
          fontSize:16,
          display: 'flex',
          alignItems: 'center',
          alignSelf:'center'
        },
  
      };
        if(this.state.isLoading){
            return(
                <LoadingScreen/>
            )
        }
        if(this.state.error){
          return(
            <div>
              <Topbar history={this.props.history}/>
              <br></br>
              <br></br>
              <br></br>
              <ErrorView/>
            </div>
          )
        }
        else {
          return (
            <div style={{width:'100vw'}}>
              <Topbar history={this.props.history} />
              {/* <img src={FermateImg} style={{width:1518,height:280}}/> */}
              <div style={{backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',height:'20vh',width:'100vw',backgroundImage:"url("+FermateImg+")",minHeight:280}}></div>
              <div style={{ justifyContent: 'center', marginLeft: 20 }}>
              <br></br>
                <Paper elevation={0} >
                  <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Button onClick={() => { this.props.history.push('/') }}>
                      Home Page
                        </Button>
                    <Typography color="grey">ELENCO FERMATE</Typography>
                  </Breadcrumbs>
                </Paper>
                <TextField
                  id="standard-name"
                  label="Cerca una Fermata"
                  onChange={(event) => { this.handleChange(event) }}
                  margin="normal"
                  value={this.state.text}
                  style={{width:window.innerWidth>500?'30%':'70%' }}
                  autoComplete={"off"}
                />
                <br />
                <Button variant="contained" color="primary"
                  onClick={() => {
                    if(this.state.text!='' && this.state.ricercaEffettuata==false){
                      this.filtraFermate(this.state.text)
                    }
                    else{
                        this.setState({
                          isLoading:true,
                          stops:[],
                          ricercaEffettuata:false
                        })
                        this.state.lastPage=1
                        this.caricaFermate() 
                      }
                    
                  }}>
                  {this.state.ricercaEffettuata?'Ricarica elenco fermate':'Ricerca Fermate'}
                </Button>
              </div>
              <div style={{minHeight:'99vh'}}>
              <List id='fermate'>
                {this.state.stops.map(
                  (element) => {
                    return (
                      <ListItem
                        button
                        onClick={(event) => {
                          this.props.history.push('/Fermate/FermateDettaglio', { Fermata : element })
                         }
                        }
                        style={{ margin: 20 }}
                      >
                        <ListItemIcon>
                          <LocationOn />
                        </ListItemIcon>
                        <ListItemText primary={element.address} />
                      </ListItem>
                    )
                  })
                }
              </List>
              <Button
              disabled={this.state.ricercaEffettuata}
              style={{margin:15}}
                  onClick={() => {
                    this.state.lastPage=this.state.lastPage+1
                    this.caricaFermate()  
                    
                  }}>
                    Carica altre fermate...
                </Button>
                <br></br>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={this.state.snackbarOpen}
                autoHideDuration={5000}
                onClose={()=>{
                  this.setState({
                    snackbarOpen:false
                  })
                }}
              >
                <SnackbarContent
                  style={classes.error}
                  aria-describedby="client-snackbar"
                  message={
                    <span style={classes.message}>
                      <ErrorIcon style={classes.icon} />
                      {"Nessun risultato trovato "}
                    </span>
                  }
                  action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={()=>{this.setState({
                      snackbarOpen:false
                    })}}>
                      <CloseIcon className={classes.icon,classes.iconVariant} />
                    </IconButton>,
                  ]}
                />
              </Snackbar>
              <CookieComponent/>
                </div>
              <Footer/>
            </div>
        );
    }
  }
}
