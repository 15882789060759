import React from 'react';
import DettaglioCorsa from '../../../Utils/DettaglioCorsa';

export default class PianificaViaggioCorsa extends React.Component {

    constructor(props){
        super(props)
        this.state={
          isLoading: true, 
                
        }
      }

      render(){
          return(
               <div>
                  <DettaglioCorsa history={this.props.history} travel = {this.props.location.state.travel}/>
                </div>
          )
      }
}