
import React from 'react';
import Topbar from '../../Utils/TopBar';
import {fetchData} from '../../Utils/network';
import { List, ListItem, ListItemIcon, ListItemText, Paper, Breadcrumbs, Typography, Button,
Snackbar,SnackbarContent,IconButton } from '@material-ui/core';
import LoadingScreen from '../../Utils/LoadingScreen';
import Timeline from '@material-ui/icons/Timeline';
import Footer from '../../Utils/Footer'
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import L from 'leaflet';
import { Map, Marker, TileLayer, Tooltip } from 'react-leaflet';
import CookieComponent from '../../Utils/CookieComponent';
export default class FermateDettaglio extends React.Component {
    
    constructor(){
      super()
      this.state={
        isLoading: true, 
        travels:[],
        snackbarOpen:false,       
      }
    }

    componentDidMount(){
      this.caricaCorse()
    }

    caricaCorse() {
      let stop=this.props.location.state.Fermata
      this.setState({ isLoading: true });
      fetchData('travels/bydurf/' + stop.durfCode)
        .then((responseJson) => {
          if (responseJson.error != undefined) {
            this.setState({
              isLoading: false,
              error: true
            });
            return;
          }
          let i = 1;
          let corse = responseJson;
          corse.forEach(t => t.num = i++);
          //ordino le corse per orario di passaggio del bus
          corse.sort((t,t2)=>{
            let firstStop=t.stops.find((s)=>{
             return s.durfCode==this.props.location.state.Fermata.durfCode
            })
            let secondStop=t2.stops.find((s)=>{
             return s.durfCode==this.props.location.state.Fermata.durfCode
            })
            let firstScheduledArrival=new Date()
            firstScheduledArrival.setHours(firstStop.time.split(':')[0])
            firstScheduledArrival.setMinutes(firstStop.time.split(':')[1])
            let secondScheduledArrival=new Date()
            secondScheduledArrival.setHours(secondStop.time.split(':')[0])
            secondScheduledArrival.setMinutes(secondStop.time.split(':')[1])
            return firstScheduledArrival-secondScheduledArrival;
          })
          stop.travels = corse;
          // if (linee.length == 0) {
          //   this.setState({
          //     snackbarOpen:true,
          //     currentDurfCode:stop.durfCode
          //   })
          //   this.props.history.push('/Fermate/FermateElenco')
          // } else {
            this.setState({
              isLoading:false,
              travels:corse
            })
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    orderTravelsByTime(){
      
    }

    searchDurf(stops){
      let hour="";
      for(let i=0;i<stops.length;i++){
        let oraStop=new Date();
        let oraAttuale = new Date();
        oraStop.setHours(stops[i].time.split(":")[0])
        oraStop.setMinutes(stops[i].time.split(":")[1])
        let differenceTime=new Date(oraStop-oraAttuale)
        if(this.props.location.state.Fermata.durfCode==stops[i].durfCode && oraStop > new Date()){
          hour=(differenceTime.getHours()-1)+" ore e "+differenceTime.getMinutes() +" minuti"
          //hour=stops[i].time+""
          break;
        }
        else{
          hour=''
        }
        
      }
      return hour;
    }
      


    render() {
      const classes = {
        error: {
          backgroundColor: "#d32f2f",
        },
        icon: {
          size: 50,
          margin:10,
          marginLeft:15,
          marginRight:15,
        },
        iconVariant: {
          opacity: 0.9,
          marginRight:10
        },
        message: {
          fontSize:16,
          display: 'flex',
          alignItems: 'center',
          alignSelf:'center'
        },
  
      };
      const myPointerIcon = new L.Icon({
        iconUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
        iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
        iconAnchor: [12.5, 40],
        popupAnchor: [10, -44],
        iconSize: [25, 40],
        shadowUrl: 'leaflet/dist/images/marker-shadow.png',
        shadowSize: [68, 95],
        shadowAnchor: [20, 92],
      })       
      const p1=L.latLng(  37.522050, 14.767147);
      const p2=L.latLng(40.505361, 17.903747);
      const mapBounds=L.latLngBounds(p1,p2);
      if(this.state.isLoading){
        return(
          <div>
            <LoadingScreen/>
          </div>
        )
      }
      if(this.state.isLoading==false){
      return (
        
        <div>
           <Topbar history={this.props.history} />
           <br></br>
          <Paper elevation={0} >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Button onClick={() => { this.props.history.push('/') }}>
                Home Page
                        </Button>
              <Button onClick={() => { window.history.back() }}>
                Elenco Fermate
                        </Button>
              <Typography color="grey">DETTAGLIO FERMATA</Typography>
            </Breadcrumbs>
          </Paper>
          <br></br>
          <Map keyboard={false} scrollWheelZoom={false} doubleClickZoom={false} touchZoom={false} style={{ marginLeft:"5%",height: "70vh", width:"90vw" }} center={[this.props.location.state.Fermata.latitude, this.props.location.state.Fermata.longitude]} maxBounds={mapBounds} zoom={13}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            <Marker icon={myPointerIcon} position={[this.props.location.state.Fermata.latitude, this.props.location.state.Fermata.longitude]}>
            <Tooltip>{this.props.location.state.Fermata.address}</Tooltip>
            </Marker>
          </Map>
          <br></br>
          <br></br>
          <Typography style={{marginLeft:20, fontWeight:"500",fontStyle:'italic'}}>DurfCode: {this.props.location.state.Fermata.durfCode} </Typography>
          <Typography style={{marginLeft:20, fontWeight:"500",fontStyle:'italic'}}>Coordinate fermata: {this.props.location.state.Fermata.latitude} - {this.props.location.state.Fermata.longitude}  </Typography> 
          <Typography style={{marginLeft:20, fontWeight:"500",fontStyle:'italic'}}>{this.state.travels && this.state.travels.length>0?"Elenco corse passanti per " + this.props.location.state.Fermata.address:'Nessuna corsa passante per questa fermata!' }</Typography>
            <List>
                {this.state.travels?this.state.travels.map(
                  (travel) => {
                    return (
                      <ListItem
                        button
                        onClick={(event) => {
                          let substrings = travel.code.split("-");
                          let code = substrings[0] + "-" + substrings[1]
                            this.props.history.push('/Corse/CorseDettaglioAndata', {travel : travel})
                          }
                        }
                        style={{ margin: 15,width:'99vw' }}
                      >
                         <ListItemIcon>
                          <Timeline/>
                        </ListItemIcon> 
                        <ListItemText primary = {travel.code +"-"+travel.legacyCode } secondary = {"Da "+travel.stops[0].name + " a "+ travel.stops[travel.stops.length-1].name + (this.searchDurf(travel.stops)!=='' ? ' passaggio previsto tra '+this.searchDurf(travel.stops) : ' ( già passato )')}/>
                      </ListItem>
                    )
                  }):null}
              </List>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={this.state.snackbarOpen}
                autoHideDuration={5000}
                onClose={()=>{
                  this.setState({
                    snackbarOpen:false
                  })
                }}
              >
                <SnackbarContent
                  style={classes.error}
                  aria-describedby="client-snackbar"
                  message={
                    <span style={classes.message}>
                      <ErrorIcon style={classes.icon} />
                      {"Nessuna linea trovata per il durf : "+ this.state.currentDurfCode}
                    </span>
                  }
                  action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={()=>{this.setState({
                      snackbarOpen:false
                    })}}>
                      <CloseIcon className={classes.icon,classes.iconVariant} />
                    </IconButton>,
                  ]}
                />
              </Snackbar>
              <CookieComponent/>
              <Footer/>
        </div>
      );
    }
    }
  } 