import React from 'react';
import {fetchData} from '../../Utils/network';
import TopBar from '../../Utils/TopBar';
import DettaglioOperatoreImg from '../../Images/DettaglioOperatoreImg.jpg';
import { Breadcrumbs, Paper,  Typography,Button } from '@material-ui/core';
import Footer from '../../Utils/Footer';
import CookieComponent from '../../Utils/CookieComponent';

export default class OperatoriDettaglio extends React.Component{
    constructor(){
        super()
        this.state={
            operatore:{},
        }
    }

    componentDidMount(){
        this.getOperatore()
    }

    getOperatore(){
        fetchData('operators/' + this.props.location.state.idOperatore)
            .then((responseJson) => { 
                let error = false;
                let message = ''; 
                if(responseJson.error != undefined) {
                    error = true;                    
                } else if(responseJson == null) {
                    error = true;
                    message = responseJson.message;
                } else {
                    let op = responseJson;                    
                    this.setState({
                        operatore : op, 
                    });                  
                }                               
                this.setState({
                    isLoading : false,
                    error : error,
                    message : message
                });                                        
            });
    }


    render(){
        return(
                <div>
                    <TopBar history={this.props.history}/>
                    <div style={{backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',height:'20vh',width:'100vw',backgroundImage:"url("+DettaglioOperatoreImg+")",minHeight:280}}></div>
                    <br></br>
                    <Paper elevation={0} >
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Button onClick={()=>{this.props.history.push('/')}}>
                            Home Page
                        </Button>
                        <Button onClick={()=>{this.props.history.push('/Operatori/OperatoriElenco')}}>
                            Elenco Operatori
                        </Button>
                        <Typography color="grey">DETTAGLIO OPERATORE</Typography>
                        </Breadcrumbs>
                    </Paper>
                    <div  style={{ display:'flex',alignItems:'center' , flexDirection:'column'}}>
                        <br></br>
                        <Typography style={{margin:5, fontSize:22,fontStyle:'italic'}}>Nome Operatore : {this.state.operatore.name}</Typography>
                        <Typography style={{margin:10, fontSize:18,fontStyle:'italic'}}>Indirizzo : {this.state.operatore.address}</Typography>
                        <Typography style={{margin:10, fontSize:18,fontStyle:'italic'}}>Città : {this.state.operatore.location}</Typography>
                        <Typography style={{margin:10, fontSize:18,fontStyle:'italic'}}>Email : {this.state.operatore.email}</Typography>
                        <Typography style={{margin:10, fontSize:18,fontStyle:'italic'}}>Num. Telefono : {this.state.operatore.phone}</Typography>
                        <Typography style={{margin:10, fontSize:18,fontStyle:'italic'}}>Sito Web : {this.state.operatore.website}</Typography>
                        <br></br>
                        <CookieComponent/>
                        <Footer/>

                    </div>
                    
                </div>
                 
        )
    }
}