import React from 'react';
import CookieConsent from "react-cookie-consent";

export default class CookieComponent extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <CookieConsent
                location="bottom"
                buttonText="Accetta cookie"
                cookieName="COReCookie"
                style={{ background: "#5786b5", height:'12vh',zIndex:9999 }}
                buttonStyle={{ color: "#2651bd", fontSize: "14px",backgroundColor:'#ffffff',flex:0.5,margin:5 }}
                expires={window.configurations.cookie.expires}
            >
                <span style={{fontSize:14}}>{window.configurations.cookie.message}</span>
            </CookieConsent>
        )
    }
}