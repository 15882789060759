import React from 'react';
import Topbar from '../../Utils/TopBar';
import { Map, Marker, TileLayer, Tooltip, Circle } from 'react-leaflet';
import { fetchData } from '../../Utils/network';
import { maxDistanceInKm, maxNumStops } from '../../resources/parameters.json';
import LoadingScreen from '../../Utils/LoadingScreen';
import Footer from '../../Utils/Footer'
import { Button, Paper, Breadcrumbs, Typography, Snackbar, SnackbarContent, IconButton, List,ListItem,ListItemIcon,ListItemText } from '@material-ui/core';
import L from 'leaflet';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CookieComponent from '../../Utils/CookieComponent';
import LocationOn from '@material-ui/icons/LocationOn';



export default class FermateRicerca2 extends React.Component {

    constructor() {
        super()
        this.state = {
            isLoading: false,
            position: null,
            stops: [],
            error: false,
            latitude: null,
            longitude: null,
            snackbarOpen:false,
            noLinesOpen:false,
        }
    }

    caricaLinee(stop){
      this.setState({isLoading: true});
      fetchData('lines/bydurf/' + stop.durfCode)
        .then((responseJson) => {
          if(responseJson.error != undefined){
            this.setState({
              isLoading:false,
              error:true
            })
            return
          }
          let i = 1;
          let linee = responseJson;
          linee.forEach(l => l.num = i++);            
          stop.lines = linee;
          this.setState({
            isLoading: false
          })        
          if(linee.length == 0) {
            this.setState({
              noLinesOpen:true
            })
          } else {
            this.props.history.push('/Fermate/FermateDettaglio', { Fermata : stop })
          }
        })
        .catch((error) =>{
          console.error(error);
        });
    }

    caricaFermate() {
        fetchData('stops/nearto/' + this.state.latitude + '/' + this.state.longitude + '/' + { maxDistanceInKm }.maxDistanceInKm + '/' + { maxNumStops }.maxNumStops)
            .then((responseJson) => {
                if (responseJson.error != undefined) {
                    this.setState({
                        error: true,
                    })
                    return
                }
                let i = 1;
                let fermate = responseJson;
                fermate.forEach(f => {
                    f.num = i;
                    i++;
                });
                this.setState({
                    isLoading: false,
                    stops: fermate,
                    error: false
                })
                if (fermate.length == 0) {
                    //alert("Nessuna fermata presente nel raggio di " + { maxDistanceInKm }.maxDistanceInKm + " km")
                    this.setState({
                        snackbarOpen:true,
                    })
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: true, isLoading: false })
            });
    }

    render() {
        const classes = {
            error: {
              backgroundColor: "#d32f2f",
            },
            icon: {
              size: 50,
              margin:10,
              marginLeft:15,
              marginRight:15,
            },
            iconVariant: {
              opacity: 0.9,
              marginRight:10
            },
            message: {
              fontSize:16,
              display: 'flex',
              alignItems: 'center',
              alignSelf:'center'
            },
      
          };
        const pointerIcon = new L.Icon({
            iconUrl: require('../../Images/leafletMarkers/marker-icon.png'),
            iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon.png'),
            iconAnchor: [12.5, 40],
            popupAnchor: [10, -44],
            iconSize: [25, 40],
            shadowUrl: 'leaflet/dist/images/marker-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [20, 92],
          })
          const myPointerIcon = new L.Icon({
            iconUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
            iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
            iconAnchor: [12.5, 40],
            popupAnchor: [10, -44],
            iconSize: [25, 40],
            shadowUrl: 'leaflet/dist/images/marker-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [20, 92],
          })
        const p1=L.latLng(  37.522050, 14.767147);
        const p2=L.latLng(40.505361, 17.903747);
        const mapBounds=L.latLngBounds(p1,p2);

        if (this.state.isLoading) {
            return (
                <div>
                    <LoadingScreen />
                </div>
            )
        }
        return (
            <div>
                <Topbar history={this.props.history} />
                <div>
                    <Paper elevation={0} >
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            <Button onClick={() => { this.props.history.push('/') }}>
                                Home Page
                        </Button>
                            <Typography color="grey">MAPPA RICERCA FERMATE</Typography>
                        </Breadcrumbs>
                    </Paper>
                    <p style={{ fontStyle: 'italic', fontWeight: 500 }}>Cliccando su un punto qualsiasi della mappa è possibile visualizzare tutte le fermate presenti nel raggio di 3Km </p>
                    <Map
                        zoom={this.state.latitude!=null && this.state.longitude!=null? 13 : 9}
                        style={{ height: "100vh",cursor:'pointer' }}
                        center={[this.state.latitude ? this.state.latitude : 39.170338, this.state.longitude ? this.state.longitude : 16.530721]}
                        onClick={(event) => {
                            this.setState({
                                isLoading: true
                            })
                            let lat = event.latlng.lat
                            let lon = event.latlng.lng
                            this.setState({
                                latitude: lat,
                                longitude: lon,
                            })
                            this.caricaFermate()

                        }}
                        maxBounds={mapBounds}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                        {this.state.latitude!=null && this.state.latitude!=null? 
                        <Marker icon={myPointerIcon} position={[this.state.latitude, this.state.longitude]} zIndexOffset={20}>
                            <Tooltip>La mia posizione</Tooltip>
                        </Marker>:null}
                        {this.state.latitude!=null && this.state.longitude!=null?
                        <Circle color={'green'} fillColor={'green'} center={[this.state.latitude, this.state.longitude]} radius={maxDistanceInKm*1000}>
                        </Circle>
                        :null}
                        {this.state.stops != undefined && this.state.stops != null ?
                            this.state.stops.map((stop, key) => {
                                return (
                                    <Marker icon={pointerIcon} position={[stop.latitude, stop.longitude]}>
                                        <Tooltip>{stop.address}</Tooltip>
                                    </Marker>
                                )
                            }) : null}
                    </Map>
                    <List>
                        {this.state.stops.map(
                            (stop) => {                   
                            return (
                                <ListItem
                                    button          
                                    onClick={(event) => {
                                          this.caricaLinee(stop)
                                          }
                                        }
                                    style={{margin:15,width:'99vh'}}    
                                    >  
                                    <ListItemIcon> 
                                        <LocationOn />
                                    </ListItemIcon>
                                    <ListItemText primary = { stop.num+")"+  stop.address } />               
                                </ListItem>        
                                )             
                            })                            
                        }
                    </List>

                </div>
                <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={this.state.snackbarOpen}
                autoHideDuration={5000}
                onClose={()=>{
                  this.setState({
                    snackbarOpen:false
                  })
                }}
              >
                <SnackbarContent
                  style={classes.error}
                  aria-describedby="client-snackbar"
                  message={
                    <span style={classes.message}>
                      <ErrorIcon style={classes.icon} />
                      {"Nessuna fermata presente nel raggio di " + { maxDistanceInKm }.maxDistanceInKm + " km"}
                    </span>
                  }
                  action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={()=>{this.setState({
                      snackbarOpen:false
                    })}}>
                      <CloseIcon className={classes.icon,classes.iconVariant} />
                    </IconButton>,
                  ]}
                />
              </Snackbar>
              <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        open={this.state.noLinesOpen}
                        autoHideDuration={5000}
                        onClose={()=>{
                          this.setState({
                            noLinesOpen:false
                          })
                        }}
                      >
                        <SnackbarContent
                          style={classes.error}
                          aria-describedby="client-snackbar"
                          message={
                            <span style={classes.message}>
                              <ErrorIcon style={classes.icon} />
                              {"Nessuna linea trovata per la fermata selezionata!"}
                            </span>
                          }
                          action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={()=>{this.setState({
                              noLinesOpen:false
                            })}}>
                              <CloseIcon className={classes.icon,classes.iconVariant} />
                            </IconButton>,
                          ]}
                        />
                      </Snackbar>
              <CookieComponent/>
            <Footer/>
            </div>)
    }
}
