import React from 'react';
import { Tooltip , IconButton } from '@material-ui/core'
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';

export default class TooltipSuggestion extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <Tooltip style={{margin:10}} placement='bottom-start' title={this.props.message}>
            <IconButton aria-label="delete">
              <HelpOutlineOutlined/>
            </IconButton>
          </Tooltip>
        )
    }
}