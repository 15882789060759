import React from 'react';
import Topbar from '../../Utils/TopBar';
import { Map, Marker, TileLayer, Tooltip, Circle } from 'react-leaflet';
import {fetchData} from '../../Utils/network';
import { maxDistanceInKm , maxNumStops } from '../../resources/parameters.json';
import LoadingScreen from '../../Utils/LoadingScreen';
import ErrorScreen from '../../Utils/ErrorView';
import { List, ListItem, ListItemIcon, ListItemText, Button, Paper, Breadcrumbs, Typography, Snackbar, SnackbarContent,IconButton } from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';
import Footer from '../../Utils/Footer'
import L from 'leaflet';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CookieComponent from '../../Utils/CookieComponent';


export default class FermateVicine2 extends React.Component {

    constructor(){
        super()
        this.state={
          isLoading: true,
          position: null,
          stops: [],
          error: false,
          snackbarOpen:false, 
          noLinesOpen:false,
                
        }
      }

      componentDidMount(){
        this.caricaFermateVicine(); 
      }

      caricaFermateVicine() {
        this.setState({isLoading : true});
        //Promise che si occupa di ottenere la posizione corrente dal gps integrato nel dispositivo
        new Promise(function(resolve, reject) {    
          navigator.geolocation.getCurrentPosition(
            pos => { 
                            
              resolve(pos);                      
            },
            error => { resolve({message : 'error'}) },
            { enableHighAccuracy: false, timeout: 20000 }
          );
        }).then(
          
          (pos) => {               
            if(pos.error != undefined){
              this.setState({
                isLoading:false,
                error:true,
              })
              return;
            }
            this.setState({isLoading : true, error : false});
            //cerco le fermate vicine alla posizione gps corrente    
            fetchData('stops/nearto/' + pos.coords.latitude + '/' + pos.coords.longitude + '/' + {maxDistanceInKm}.maxDistanceInKm + '/' + {maxNumStops}.maxNumStops)        
              .then((responseJson) => {
                if(responseJson.error != undefined){
                  this.setState({
                    isLoading:false,
                    error:true,
                  })
                  return
                }
                let i = 1;
                let fermate = responseJson;
                fermate.forEach(f => {
                  f.num = i;
                  i++;
                  f.travelsList=[]
                });
                for(let i=0;i<fermate.length;i++){
                  fetchData('travels/bydurf/' + fermate[i].durfCode)
                    .then((responseJson) => {
                      if(responseJson.error == undefined){
                      fermate[i].travelsList=responseJson
                    }
                  })
                }
                 
                this.setState({
                  isLoading: false,
                  position: pos,
                  stops: fermate,
                  error: false
                })   
                if(fermate.length == 0) {
                 //alert("Nessuna fermata presente nel raggio di " + {maxDistanceInKm}.maxDistanceInKm + " km")
                 this.setState({
                   snackbarOpen:true
                 })
                }
            })
            .catch((error) =>{
              console.error(error);
              this.setState({error: true, isLoading: false})
            });        
          }
        );
      }

      caricaLinee(stop){
        this.setState({isLoading: true});
        fetchData('lines/bydurf/' + stop.durfCode)
          .then((responseJson) => {
            if(responseJson.error != undefined){
              this.setState({
                isLoading:false,
                error:true
              })
              return
            }
            let i = 1;
            let linee = responseJson;
            linee.forEach(l => l.num = i++);            
            stop.lines = linee;
            this.setState({
              isLoading: false
            })        
            if(linee.length == 0) {
              this.setState({
                noLinesOpen:true
              })
            } else {
              this.props.history.push('/Fermate/FermateDettaglio', { Fermata : stop })
            }
          })
          .catch((error) =>{
            console.error(error);
          });
      }

      render(){
        const classes = {
          error: {
            backgroundColor: "#d32f2f",
          },
          icon: {
            size: 50,
            margin:10,
            marginLeft:15,
            marginRight:15,
          },
          iconVariant: {
            opacity: 0.9,
            marginRight:10
          },
          message: {
            fontSize:16,
            display: 'flex',
            alignItems: 'center',
            alignSelf:'center'
          },
    
        };
        const pointerIcon = new L.Icon({

          iconUrl: require('../../Images/leafletMarkers/marker-icon.png'),
          iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon.png'),
          iconAnchor: [12.5, 40],
          popupAnchor: [10, -44],
          iconSize: [25, 40],
          shadowUrl: 'leaflet/dist/images/marker-shadow.png',
          shadowSize: [68, 95],
          shadowAnchor: [20, 92],
        })
        const myPointerIcon = new L.Icon({
          iconUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
          iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
          iconAnchor: [12.5, 40],
          popupAnchor: [10, -44],
          iconSize: [25, 40],
          shadowUrl: 'leaflet/dist/images/marker-shadow.png',
          shadowSize: [68, 95],
          shadowAnchor: [20, 92],
        })
        const p1=L.latLng(  37.522050, 14.767147);
        const p2=L.latLng(40.505361, 17.903747);
        const mapBounds=L.latLngBounds(p1,p2);
        
        if(this.state.error){
          return(
              <div>
                  <Topbar history={this.props.history}/>
                  <br/>
                  <br/>
                  <ErrorScreen/>
              </div>
          )
      }

          if(this.state.isLoading){
            return(
              <div>
                <LoadingScreen />
              </div>
            )
          }

        
          else{
          return(
               <div>
                  <Topbar history={this.props.history} />
                  <br></br>
                  <Paper elevation={0} >
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                      <Button onClick={() => { this.props.history.push('/') }}>
                        Home Page
                            </Button>
                      <Typography color="grey">ELENCO FERMATE VICINE</Typography>
                    </Breadcrumbs>
                  </Paper>                                                  
                    <div>

                    <p style={{ fontStyle: 'italic', fontWeight: 500 }}>Mappa con le fermate presenti nelle vicinanze:</p>
                    <Map keyboard={false} scrollWheelZoom={false} doubleClickZoom={false} touchZoom={false} style={{ height: "80vh" }} center={[this.state.position.coords.latitude, this.state.position.coords.longitude]} maxBounds={mapBounds} zoom={14}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            />
                        <Marker icon={myPointerIcon} position={[this.state.position.coords.latitude, this.state.position.coords.longitude]}>
                            <Tooltip>La mia posizione</Tooltip>
                        </Marker>
                        <Circle color={'green'} fillColor={'green'} center={[this.state.position.coords.latitude, this.state.position.coords.longitude]} radius={maxDistanceInKm*1000}></Circle>
                        {this.state.stops?this.state.stops.map(
                            (stop,key) => {             
                            return (
                            <Marker icon = {pointerIcon} position={[stop.latitude, stop.longitude]}>
                              <Tooltip>{key+1} ) {stop.address}</Tooltip>
                            </Marker>
                            )
                        }):null}
                    </Map>
                    {this.state.stops ? (
                    <List>
                        {this.state.stops.map(
                            (stop) => {       
                            return (
                                <ListItem
                                    button          
                                    onClick={(event) => {
                                          this.caricaLinee(stop)
                                          }
                                        }
                                    style={{margin:15,width:'99vh'}}    
                                    >  
                                    <ListItemIcon> 
                                        <LocationOn />
                                    </ListItemIcon>
                                    <ListItemText primary = { stop.num+")"+  stop.address } />               
                                </ListItem> 
                                )             
                            })                            
                        }
                    </List>) : null }                       
                     </div>
                     <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        open={this.state.snackbarOpen}
                        autoHideDuration={5000}
                        onClose={()=>{
                          this.setState({
                            snackbarOpen:false
                          })
                        }}
                      >
                        <SnackbarContent
                          style={classes.error}
                          aria-describedby="client-snackbar"
                          message={
                            <span style={classes.message}>
                              <ErrorIcon style={classes.icon} />
                              {"Nessuna fermata presente nel raggio di " + {maxDistanceInKm}.maxDistanceInKm + " km o nessuna linea trovata"}
                            </span>
                          }
                          action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={()=>{this.setState({
                              snackbarOpen:false
                            })}}>
                              <CloseIcon className={classes.icon,classes.iconVariant} />
                            </IconButton>,
                          ]}
                        />
                      </Snackbar>
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        open={this.state.noLinesOpen}
                        autoHideDuration={5000}
                        onClose={()=>{
                          this.setState({
                            noLinesOpen:false
                          })
                        }}
                      >
                        <SnackbarContent
                          style={classes.error}
                          aria-describedby="client-snackbar"
                          message={
                            <span style={classes.message}>
                              <ErrorIcon style={classes.icon} />
                              {"Nessuna corsa trovata!"}
                            </span>
                          }
                          action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={()=>{this.setState({
                              noLinesOpen:false
                            })}}>
                              <CloseIcon className={classes.icon,classes.iconVariant} />
                            </IconButton>,
                          ]}
                        />
                      </Snackbar>
                      
                      <CookieComponent/>
                     <Footer/> 
                    </div>)
      }}
}

