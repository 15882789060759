import React from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@material-ui/core';

import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import { getComuniByProvincia } from './utility';

export default class AutocompleteComuni extends React.Component{

    constructor(props){
        super(props); 
        this.state = {
            comune : null
        }       
    }        

    render(){
        return(            
            <Autocomplete
                disabled={this.props.provincia == null}
                disablePortal
                style={{marginTop : 25, width : '70%'}}                                
                options = {this.props.elencoComuni}
                loadingText = 'In caricamento'
                noOptionsText = 'Nessun dato trovato'
                value = {this.state.comune}
                onChange = {(event, value) => {                    
                    if(value == null) return;
                    this.setState({comune : value})
                    //cerco il comune nell'elenco
                    let c = getComuniByProvincia(this.props.that.state.provincia)[value.id];                                                           
                    setTimeout(() => {                       
                        //valorizzo latitudine e longitudine del comune selezionato                                               
                        geocodeByAddress(c + ', ' + this.props.provincia)                        
                        .then(results => {                                
                            return getLatLng(results[0])
                        })                                                
                        .then(latLng => {                            
                            let comune = {
                                nome : c,
                                latitudine : latLng.lat,
                                longitudine : latLng.lng
                            }
                            this.props.that.setState({
                                comune : comune,
                                indirizzo : ''
                            })                            
                            if(this.props.onSelect && this.props.onSelect != null) {
                                this.props.onSelect(comune);
                            }
                        })                         
                    }, 100)                    
                }}
                renderInput = {(params) => 
                    <TextField
                        {...params}
                        fullWidth
                        required
                        style = {{marginTop : 10}}                        
                        variant = 'standard'
                        placeholder = {this.props.placeholder}
                    />
                }
            />
        )
    }

}