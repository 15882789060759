import React from 'react';
import Topbar from '../../Utils/TopBar';
import DettaglioLinee from '../../Utils/DettaglioLinee';

export default class FermateDettaglio extends React.Component {

    constructor(){
        super()
        this.state={
          isLoading: true, 
          lines:[],       
        }
      }

      render(){
          return(
            <div>
                <DettaglioLinee history={this.props.history} lineId = {this.props.location.state.lineId} navigateTo = '/Fermate/FermateLineaAndataDettaglio' isOutBound = {false} detail = '/Corse/CorseDettaglioRitorno' lineCode={this.props.location.state.lineCode}/>
            </div>  
          )
      }
}