import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import loghi from '../Images/loghi.png'

export default class Footer extends React.Component{
    render(){
        return(
                <AppBar position="static" style={{overflow:'hidden',position:'relative',bottom:0}}> 
                    <table style={{backgroundColor:'#D9D9D9'}}>
                        <tr>
                            <td style={{width : '33%'}}>

                            </td>
                            <td style={{width : '40%'}}>
                                <img src={loghi} style={{height:window.innerWidth>600?'12vh':'auto',width:window.innerWidth>500?'':'40vw'}}/>
                            </td>
                            <td style={{width : '26%'}}>
                                <a href='https://form.agid.gov.it/view/311e743f-580b-4583-aaf9-456b3450904e'>Dichiarazione d'accessibilità</a>
                            </td>
                        </tr>                        
                    </table>
                {/* <Toolbar style={{justifyContent:'space-evenly',alignContent:'space-between',maxWidth:'100vw', backgroundColor:'#D9D9D9'}}>
                    <img src={loghi} style={{height:window.innerWidth>600?'12vh':'auto',width:window.innerWidth>500?'':'40vw'}} />                    
                </Toolbar> */}
                </AppBar>    
        )
    }
}