import React from 'react';
import Topbar from '../../Utils/TopBar';
import DettaglioLinee from '../../Utils/DettaglioLinee';

export default class FermateLineeAndataDettaglio extends React.Component {

    constructor(){
        super()
        this.state={
          isLoading: true, 
                
        }
      }

      render(){
          return(
               <div>
                  <DettaglioLinee history={this.props.history} lineId = {this.props.location.state.lineId} isOutBound = {true} navigateTo = '/Fermate/FermateLineaRitornoDettaglio'  detail = '/Corse/CorseDettaglioAndata' lineCode={this.props.location.state.lineCode}/> 
                </div>
          )
      }
}