import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { getComuniByProvincia } from './utility';

export default class SelectProvince extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            elenco : [
                <MenuItem value = {'CZ'}>CATANZARO</MenuItem>,
                <MenuItem value = {'CS'}>COSENZA</MenuItem>,
                <MenuItem value = {'KR'}>CROTONE</MenuItem>,
                <MenuItem value = {'VV'}>VIBO VALENTIA</MenuItem>,
                <MenuItem value = {'RC'}>REGGIO CALABRIA</MenuItem> 
            ]
        };   
    }

    ricaricaElencoComuni(provincia) {        
        let comuniAC = [];
        let i = 0;
        getComuniByProvincia(provincia)
            .forEach(c => {
                let a = {                    
                    id : i,
                    label : c.toUpperCase()
                }
                i++;
                comuniAC.push(a);
            })
        this.props.that.setState({
            elencoComuni : comuniAC
        })
    }

    render(){
        return(
            <Select
                required
                fullWidth
                displayEmpty
                style={{marginTop : 10, width : '70%'}}                                
                onChange = {(event) => {                                        
                    this.props.that.setState({
                        provincia : null,
                        comune : null
                    })
                    setTimeout(() => {
                        this.props.that.setState({
                            provincia : event.target.value.props.value                          

                        })
                        this.ricaricaElencoComuni(event.target.value.props.value);
                    }, 100)                                            
                }}
                renderValue={(v) => {
                    if(!v) {
                        return <em>{this.props.placeholder}</em>;
                    }
                    return <MenuItem>{v}</MenuItem>
                }}
            >
                
                {
                    (!this.state.elenco ? [] : this.state.elenco)
                        .map((e) => 
                            <MenuItem value = {e}>{e}</MenuItem>
                        )                        
                }
            </Select>

        )
    }

}