import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../Utils/Utils.css';

export default class LoadingScreen extends React.Component{

    render(){
        return(
                <div className={window.innerWidth<=500?"loading2":"loading1"} style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
                    <CircularProgress size={160} color="secondary" />
                </div>
           
        )
    }
}