import React from 'react';
import {fetchData} from '../../Utils/network';
import { TextField, List, ListItem, ListItemIcon, ListItemText, Button, Breadcrumbs,Typography, Snackbar,SnackbarContent, IconButton, Card , CardActionArea, CardMedia, CardContent,CardActions } from '@material-ui/core';
import Topbar from '../../Utils/TopBar';
import LoadingScreen from '../../Utils/LoadingScreen';
import ErrorView from '../../Utils/ErrorView';
import BusIcon from '@material-ui/icons/DirectionsBus';
import Paper from '@material-ui/core/Paper';
import OperatorImg2 from '../../Images/OperatorImg2.png';
import Footer from '../../Utils/Footer';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CookieComponent from '../../Utils/CookieComponent';
import {operatorsArray} from '../../Utils/utility';
import noicon from '../../Images/Loghi_operatori/noicon.png' 
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export default class OperatoriElenco extends React.Component {

    
    constructor(){
        super()
        this.state={
            isLoading:true,
            dataSource:[],
            filteredList:[],
            error:'',
            input:'',
            open:true,
            snackbarOpen:false
        }
    }
    
    handleDrawerOpen() {
        this.setState({
             open:true
        })
      }
    
    handleDrawerClose() {
        this.setState({
            open:false
        })
      }

    componentDidMount(){
        this.loadOperators()
    }

    handleChange(e){
        this.setState({
          input: e.target.value,
        })
      }

    loadOperators() {
        this.setState({isLoading : true});
        return fetchData('operators')
          .then((responseJson) => {
            this.setState({
              isLoading: false,
              dataSource: responseJson,
              filteredList: responseJson,
              error: responseJson.error != undefined
            })
          })
          .catch((error) =>{
            console.error(error);
          });
      }


    render() {

        const classes = {
            error: {
              backgroundColor: "#d32f2f",
            },
            icon: {
              size: 50,
              margin:10,
              marginLeft:15,
              marginRight:15,
            },
            iconVariant: {
              opacity: 0.9,
              marginRight:10
            },
            message: {
              fontSize:16,
              display: 'flex',
              alignItems: 'center',
              alignSelf:'center'
            },
      
          };

        if(this.state.error){
            return(
                <div>
                    <Topbar history={this.props.history}/>
                    <br/>
                    <br/>
                    <ErrorView/>
                </div>
            )
        }
        if(this.state.isLoading){
            return(
                <LoadingScreen/>
            )
        }
        else{
        return (
            <div style={{display: 'flex',
                flexDirection: 'column'}}>
                <Topbar history={this.props.history}/>
                <div style={{backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',height:'20vh',width:'100vw',backgroundImage:"url("+OperatorImg2+")",minHeight:280}}></div>
                <div>
                    <div style={{marginLeft:30}}>
                    <br></br>
                    <Paper elevation={0} >
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Button onClick={()=>{this.props.history.push('/')}}>
                            Home Page
                        </Button>>
                        <Typography color="grey">ELENCO OPERATORI</Typography>
                        </Breadcrumbs>
                    </Paper>
                    <TextField
                        id="standard-name"
                        label="Cerca un Operatore"
                        onChange={(event)=>{this.handleChange(event)}}
                        margin="normal"
                        style={{width:window.innerWidth > 600 ? '25%':'auto'}}
                        autoComplete={"off"}
                    />
                    <Button variant="contained" color="primary"
                    style={{marginTop:25,marginLeft:10}}
                    onClick={() => {
                            let listaFiltrata=this.state.dataSource.filter(operator => operator.name.toLowerCase().includes(this.state.input.toLowerCase()))
                            if(listaFiltrata.length == 0) {
                            //alert("Nessun risultato trovato")  
                            this.setState({
                                snackbarOpen:true,
                                input:''
                            })
                            }
                            this.setState({ isLoading: false, filteredList: listaFiltrata })
                            if(this.state.input.trim()==''){
                                this.loadOperators()
                            }        
                        }}>
                        {this.state.filteredList.length>0?'Filtra':'Ricarica lista'}
                    </Button>
                    </div>
                
                    {this.state.filteredList ? (
                    <span>
                        {this.state.filteredList.map(
                          
                            (element) => {                    
                            return (
                              <ExpansionPanel style={{margin:5,borderColor:'black',borderWidth:1}}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <img style={{ height:window.innerWidth>=600?'15vh':'10vh',width:window.innerWidth>=600?'20vw':'50vw',margin:5}} src={operatorsArray[element.name] && operatorsArray[element.name]!='' ?require('../../Images/Loghi_operatori/'+operatorsArray[element.name]):noicon} />
                                <Typography style={{alignSelf:'center',fontSize:18}} gutterBottom variant="h6" component="h2">
                                   {element.name}
                                  </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <List>
                                  <Typography style={{fontSize:17}} variant="h6" color="textSecondary" component="p">
                                    Indirizzo : {element.address+" "+ element.location+" "+"("+ element.province+")"}
                                  </Typography>
                                  <Typography style={{fontSize:17}} variant="h6" color="textSecondary" component="p">
                                    telefono : {element.phone}
                                  </Typography>
                                  <Typography style={{fontSize:17}} variant="h6" color="textSecondary" component="p">
                                    email : {element.email}
                                  </Typography>
                                  <Typography style={{fontSize:17}} variant="h6" color="textSecondary" component="p">
                                    sito : {element.website}
                                  </Typography>
                                </List>
                                </ExpansionPanelDetails>
                             
                            </ExpansionPanel>        
                                )             
                            })                            
                        }
                    </span>) : null }
                    </div>
                    <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        open={this.state.snackbarOpen}
                        autoHideDuration={5000}
                        onClose={()=>{
                          this.setState({
                            snackbarOpen:false
                          })
                        }}
                      >
                        <SnackbarContent
                          style={classes.error}
                          aria-describedby="client-snackbar"
                          message={
                            <span style={classes.message}>
                              <ErrorIcon style={classes.icon} />
                              {"Nessun risultato trovato"}
                            </span>
                          }
                          action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={()=>{this.setState({
                              snackbarOpen:false
                            })}}>
                              <CloseIcon className={classes.icon,classes.iconVariant} />
                            </IconButton>,
                          ]}
                        />
                      </Snackbar>
                      <CookieComponent/>
                    <Footer/>

            </div>
        );
    }
 }
}
