import React from 'react';
import ErrorIcon from '@material-ui/icons/Warning';

export default class ErrorView extends React.Component{
    render(){
        return(
                <div>
                    <div style = {{display: 'flex',  justifyContent:'center', alignItems:'center' }}>                                
                        <ErrorIcon style={{width:150,height:150}}/>
                    </div>
                    <div style = {{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                        <div><h1 style={{alignText:'center'}}>Errore! Impossibile contattare il server!</h1></div>
                    </div>
                </div> 
        )
    }
}
