import React from 'react';
import Topbar from '../../Utils/TopBar';
import InformazioniImg from '../../Images/InformazioniImg.jpg';
import Footer from '../../Utils/Footer'
import {Paper, Breadcrumbs, Button, Typography} from '@material-ui/core';
import LoadingScreen from '../../Utils/LoadingScreen';
import "es6-promise/auto";
import ReactHtmlParser from 'react-html-parser';
import Divider from '@material-ui/core/Divider';
import {fetchNews} from '../../Utils/network';
import CookieComponent from '../../Utils/CookieComponent';

export default class Informazioni extends React.Component {

    constructor(){
        super()
        this.state={
            infos:[],
            isLoading: true,
            error: false  
        }
    }


    componentDidMount(){
        require('es6-promise').polyfill();
        this.loadInfos();
    }

    loadInfos() {
        this.setState({isLoading : true});
        let dataOdierna = new Date();
        //let dataStringa = dataOdierna.getFullYear() + '-' + (dataOdierna.getMonth()+1) + '-' + dataOdierna.getDate();
        return fetchNews('display-date/' + dataOdierna.getTime() + '/status/0/max/9999')
          .then((responseJson) => {  
            if(responseJson.error != undefined){
              this.setState({
                isLoading:false,
                error:true,
              })
              return
            }

            let s=responseJson.result;
                      
            this.setState({
              isLoading: false,
              infos: s,           
              error: false,
            }) 
            
                  
          })
          .catch((error) =>{
            console.error(error);
            this.setState({
              isLoading: false,
              error:true
            })
          });
      }


    render() {
        if(this.state.isLoading==true){
            return(
                <div>
                    <LoadingScreen/>
                </div>
            )
        }
        if(this.state.isLoading==false){
        return (
            <div>
                <Topbar history={this.props.history}/>
                <div style={{backgroundSize:'cover',backgroundPosition:'top',backgroundRepeat:'no-repeat',height:'20vh',width:'100vw',backgroundImage:"url("+InformazioniImg+")",minHeight:280}}></div>
                <br></br>
                <Paper elevation={0} >
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Button onClick={()=>{this.props.history.push('/')}}>
                            Home Page
                        </Button>
                        <Typography color="grey">INFORMAZIONI</Typography>
                        </Breadcrumbs>
                    </Paper>
                    <br></br>
                <div style={{minHeight:'40vh'}}>
                {this.state.infos.map((info)=>{
                    return(
                      info.description.includes('#info')?
                      <div style={{margin:10}}>
                        <br/>
                        <b>{ReactHtmlParser(info.title)}</b>
                          {ReactHtmlParser(info.content)}
                        <br/>
                        <Divider/>
                      </div>
                       :null
                    )
                  })}
                    
                    <br></br>
                </div>
                <CookieComponent/>
                <Footer/>
            </div>
        );
    }}
}