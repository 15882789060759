import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Topbar from '../../Utils/TopBar';
import Footer from '../../Utils/Footer'
import {Paper, Breadcrumbs, Button, Typography} from '@material-ui/core';
import CookieComponent from '../../Utils/CookieComponent';

export default class HtmlContenitor extends React.Component {

    constructor(props){
        super(props)
        this.state = {            
            
        }        
    }

    render() {
        let route = window.routes.filter(r => r.url === this.props.match.params.page)[0];
        return (   
            <div>
                <Topbar history={this.props.history}/>
                <div style={{backgroundSize:'cover',backgroundPosition:'top',backgroundRepeat:'no-repeat',height:'20vh',width:'100vw',backgroundImage:"url("+route.pictureUrl+")",minHeight:280}}></div>
                <br/>
                <Paper elevation={0} >
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Button onClick={()=>{this.props.history.push('/')}}>
                        Home Page
                    </Button>
                    <Typography color="grey">{route.title}</Typography>
                    </Breadcrumbs>
                </Paper>                
                <div style={{minHeight:'46vh'}}>
                    <div style={{margin:10}}>
                        {
                            ReactHtmlParser(route.content)
                        }                        
                    </div>
                </div>
                <CookieComponent/>
                <Footer/>
            </div>                     
        )
    }

}