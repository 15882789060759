import React from 'react';
import Topbar from '../../Utils/TopBar';
import { TextField, Button, Paper, Breadcrumbs,Typography,Dialog,AppBar,Toolbar  } from '@material-ui/core';
import { fetchFromOTP } from '../../Utils/network';
import { distinctElementsByName } from '../../Utils/utility';
import LoadingScreen from '../../Utils/LoadingScreen';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider, KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AccessTime from '@material-ui/icons/AccessTime';
import DateFnsUtils from '@date-io/date-fns';
import PianificaViaggioImg2 from '../../Images/PianificaViaggioImg2.jpg';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import Footer from '../../Utils/Footer';
import { Map, Marker, TileLayer, Tooltip, Polyline } from 'react-leaflet';
import L from 'leaflet'
import CookieComponent from '../../Utils/CookieComponent';
import {it} from 'date-fns/locale';
import TooltipSuggestion from '../../Utils/TooltipSuggestion';
import SelectProvince from '../../Utils/SelectProvince';
import AutocompleteComuni from '../../Utils/AutocompleteComuni';
import ProvinciaComuneIndirizzoAC from '../../Utils/ProvinciaComuneIndirizzoAC';

const google = window.google

export default class PianificaViaggio extends React.Component {


  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      stops: [],
      testoPartenza: '',
      testoArrivo: '',
      partenza: {
        name:'',
        lat:null,
        lon:null
      },
      indirizzo: '',
      arrivo: {
        name:'',
        lat:null,
        lon:null
      },
      dataPartenza: null,
      oraPartenza: null,
      disableDate: true,
      disableTime: true,
      disableButton: true,
      dialogPartenzaOpen:false,
      dialogArrivoOpen:false,
      startLatitude:null,
      startLongitude:null,
      endLatitude:null,
      endLongitude:null
    }

    this.setPartenza=this.setPartenza.bind(this)
    this.setArrivo=this.setArrivo.bind(this)
  }

  componentDidMount() {
    //this.loadStops();
  }

  loadStops() {
    this.setState({ isLoading: true });
    fetchFromOTP('index/stops')
      .then((res) => {
        this.setState({
          isLoading: false,
          stops: distinctElementsByName(res),
          error: res.error != undefined,
        });
      }
      );
  }

  setPartenza(event){
    let lat = event.latlng.lat
    let lon = event.latlng.lng
      this.setState({
        startLatitude: lat,
        startLongitude: lon,
        testoPartenza:'Punto di partenza ('+JSON.stringify(lat).substring(0,6)+" - "+ JSON.stringify(lon).substring(0,6)+")"
        })                  
    this.state.partenza.name="Punto specificato " +JSON.stringify(lat).substring(0,6)+ " - "+ JSON.stringify(lon).substring(0,6)
    this.state.partenza.lat=lat
    this.state.partenza.lon=lon

    if(this.state.testoArrivo!=''){
      this.setState({
        disableDate:false
      })
    }

  }

  setArrivo(event){
    let lat = event.latlng.lat
    let lon = event.latlng.lng
     this.setState({
        endLatitude: lat,
        endLongitude: lon,
        testoArrivo:'Punto di arrivo ('+JSON.stringify(lat).substring(0,6)+" - "+ JSON.stringify(lon).substring(0,6)+")"
       })

       this.state.arrivo.name="Punto specificato " +JSON.stringify(lat).substring(0,6)+ " - "+ JSON.stringify(lon).substring(0,6)
       this.state.arrivo.lat=lat
       this.state.arrivo.lon=lon

       if(this.state.testoPartenza!=''){
        this.setState({
          disableDate:false
        })
      }

  }

  setIndirizzoPartenza = (address, latLng) => {
    let partenza = {
      name : address,
      lat : latLng.lat,
      lon : latLng.lng
    }
    this.setState({
      partenza : partenza
    })        
  }

  setIndirizzoArrivo = (address, latLng) => {
    let arrivo = {
      name : address,
      lat : latLng.lat,
      lon : latLng.lng
    }
    this.setState({
      arrivo : arrivo
    })        
  }

  apriDialogPartenza = () => {
    this.setState({
      dialogPartenzaOpen : true
    })    
  }

  apriDialogArrivo = () => {
    this.setState({
      dialogArrivoOpen : true
    })
  }
  
  render() {
    const pointerIcon = new L.Icon({
      iconUrl: require('../../Images/leafletMarkers/marker-icon.png'),
      iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon.png'),
      iconAnchor: [12.5, 40],
      popupAnchor: [10, -44],
      iconSize: [25, 40],
      shadowUrl: 'leaflet/dist/images/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    })
    const myPointerIcon = new L.Icon({
      iconUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
      iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
      iconAnchor: [12.5, 40],
      popupAnchor: [10, -44],
      iconSize: [25, 40],
      shadowUrl: 'leaflet/dist/images/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    })
  const p1=L.latLng(  37.522050, 14.767147);
  const p2=L.latLng(40.505361, 17.903747);
  const mapBounds=L.latLngBounds(p1,p2);    
    if (this.state.isLoading) {
      return (
        <div><LoadingScreen /></div>
      )
    }
    else {
      return (
        <div id='divpianifica'>
          <Topbar history={this.props.history} />
          <div style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '20vh', width: '100vw', backgroundImage: "url(" + PianificaViaggioImg2 + ")", minHeight: 340 }}></div>
          <div></div>
          <Paper elevation={0} >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Button onClick={()=>{this.props.history.push('/')}}>
                Home Page
              </Button>
              <Typography color="grey">PIANIFICA VIAGGIO <TooltipSuggestion message ='In questa sezione puoi pianificare un viaggio tramite trasporto pubblico; compila il form, digitando la partenza, la destinazione (con la possibilità di selezionare il punto sulla mappa) e quando partire, dopodichè fai clic su Calcola Percorso'/></Typography>
            </Breadcrumbs>
          </Paper>
          <div 
            style={{ 
                marginLeft : window.innerWidth > 500 ? '30%' : 50, 
                width:window.innerWidth > 500 ? '50%' : '70%',
                marginTop:15,                
            }}
          >  
            <ProvinciaComuneIndirizzoAC
              title = {'Partenza'}
              setIndirizzo = {this.setIndirizzoPartenza}
              azioneMappa = {this.apriDialogPartenza}
              mostraBtn = {true}
              that = {this}
            />                      
            <ProvinciaComuneIndirizzoAC
              title = {'Arrivo'}
              setIndirizzo = {this.setIndirizzoArrivo}
              azioneMappa = {this.apriDialogArrivo}
              mostraBtn = {true}
              that = {this}
            />                      
          </div>          
          <span>            
            <br></br>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                <KeyboardDatePicker
                  style={{ marginLeft:  window.innerWidth > 500 ? '25%' : 50, marginRight: 30, marginTop:30 }}
                  format="dd/MM/yyyy"
                  id="data-viaggio"
                  label="Scegli la data"                  
                  value={this.state.dataPartenza}
                  onChange={() => { console.log("") }}
                  onAccept={(date) => {
                    this.setState({
                      dataPartenza: date,
                      disableTime: false
                    })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disabled = {
                    this.state.partenza.name == '' || this.state.arrivo.name == ''
                  }
                />
                <KeyboardTimePicker
                  style={{ marginLeft: 50, marginRight: 30,  marginTop:30 }}
                  id="time-picker"
                  ampm={false}
                  keyboardIcon={<AccessTime/>}
                  label="Scegli l'orario"
                  value={this.state.oraPartenza}
                  onChange={() => { console.log("") }}
                  onAccept={(hour) => {
                    this.setState({
                      oraPartenza: hour,
                      disableButton: false
                    })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  disabled={this.state.disableTime}
                />
              </MuiPickersUtilsProvider>
              <Button variant="contained" color="primary"
                style={{ marginTop: 10, marginLeft: 40,  marginTop:40 }}
                onClick={(event) => {
                  this.props.history.push('/PianificaViaggio/ElencoItinerari',
                    { Partenza: this.state.partenza, Arrivo: this.state.arrivo, DataPartenza: this.state.dataPartenza, OraPartenza: this.state.oraPartenza });
                }}
                disabled={this.state.disableButton}>
                Calcola percorso
                </Button>
            </div>
            {/* DIALOGS PER LA SELEZIONE DELLA POSIZIONE */}
          <Dialog id='dialog-selezione-partenza' fullScreen open={this.state.dialogPartenzaOpen} >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography style={{ alignSelf: 'center', alignText: 'center' }} variant="h6" className={classes.title}>
                 Scegli sulla mappa il punto da cui vuoi partire e clicca su OK
                </Typography>
                <Button variant="outlined" style={{marginLeft:20,color:'white'}} onClick={() => {
                  this.setState({ dialogPartenzaOpen: false })

                }} aria-label="close"
                >
                  OK
                </Button>
                <Button variant="outlined" style={{marginLeft:20,color:'white'}} onClick={() => {
                  this.setState({ dialogPartenzaOpen: false,
                    startLatitude: null,
                    startLongitude: null,
                    testoPartenza:'',
                    partenza:{},
                    })                  

                }} aria-label="close"
                >
                  Annulla
                </Button>
              </Toolbar>
            </AppBar>
            <br></br>
            <br></br>
            <br></br>
            <Map
              zoom={this.state.startLatitude!=null && this.state.startLongitude!=null? 13 : 9}
              style={{ height: "100vh",cursor:'pointer' }}
              center={[this.state.startLatitude ? this.state.startLatitude : 39.170338, this.state.startLongitude ? this.state.startLongitude : 16.530721]}
              onClick={(event) => {
                 this.setPartenza(event)
                    }}
                        maxBounds={mapBounds}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                        {this.state.startLatitude!=null && this.state.startLatitude!=null? 
                        <Marker icon={myPointerIcon} position={[this.state.startLatitude, this.state.startLongitude]} zIndexOffset={20}>
                            <Tooltip>Posizione di partenza</Tooltip>
                        </Marker>:null}
                        {this.state.endLatitude!=null && this.state.endLatitude!=null? 
                        <Marker icon={myPointerIcon} position={[this.state.endLatitude, this.state.endLongitude]} zIndexOffset={20}>
                            <Tooltip>Posizione di arrivo</Tooltip>
                        </Marker>:null}
                        {this.state.startLatitude!=null && this.state.startLatitude!=null && this.state.endLatitude!=null && this.state.endLatitude!=null? 
                        <Polyline
                          positions={[[this.state.startLatitude, this.state.startLongitude],[this.state.endLatitude, this.state.endLongitude]]}
                          color={"red"}
                        />:null}
                        
                    </Map>
            </Dialog>
            <Dialog id='dialog-selezione-arrivo' fullScreen open={this.state.dialogArrivoOpen} >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography style={{ alignSelf: 'center', alignText: 'center' }} variant="h6" className={classes.title}>
                Scegli sulla mappa il punto dove vuoi arrivare e clicca su OK
                </Typography>
                <Button variant="outlined" style={{marginLeft:20,color:'white'}} onClick={() => {
                  this.setState({ dialogArrivoOpen: false })

                }} aria-label="close"
                >
                  OK
                </Button>
                <Button variant="outlined" style={{marginLeft:20,color:'white'}} onClick={() => {
                  this.setState({ dialogArrivoOpen: false,
                    endLatitude: null,
                    endLongitude: null,
                    testoArrivo:'',
                    arrivo:{},
                    })                  

                }} aria-label="close"
                >
                  Annulla
                </Button>
              </Toolbar>
            </AppBar>
            <br></br>
            <br></br>
            <br></br>
            <Map
              zoom={this.state.endLatitude!=null && this.state.endLongitude!=null? 13 : 9}
              style={{ height: "100vh",cursor:'pointer' }}
              center={[this.state.endLatitude ? this.state.endLatitude : 39.170338, this.state.endLongitude ? this.state.endLongitude : 16.530721]}
              onClick={(event) => {
                    this.setArrivo(event)
                    }}
                    maxBounds={mapBounds}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                         {this.state.startLatitude!=null && this.state.startLatitude!=null? 
                        <Marker icon={myPointerIcon} position={[this.state.startLatitude, this.state.startLongitude]} zIndexOffset={20}>
                            <Tooltip>Posizione di partenza</Tooltip>
                        </Marker>:null}
                        {this.state.endLatitude!=null && this.state.endLatitude!=null? 
                        <Marker icon={myPointerIcon} position={[this.state.endLatitude, this.state.endLongitude]} zIndexOffset={20}>
                            <Tooltip>Posizione di arrivo</Tooltip>
                        </Marker>:null}
                        {this.state.startLatitude!=null && this.state.startLatitude!=null && this.state.endLatitude!=null && this.state.endLatitude!=null? 
                        <Polyline
                          positions={[[this.state.startLatitude, this.state.startLongitude],[this.state.endLatitude, this.state.endLongitude]]}
                          color={"red"}
                        />:null}
                        
                    </Map>
            </Dialog>

          </span>
        <br></br>
        <br></br>
        <CookieComponent/>
        <Footer/>
        </div>

      

      )
    }
  }
}

const classes = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  title: {
    marginLeft: 10,
    flex: 1,
  },
}));