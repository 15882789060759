import {maxMinutesRange} from '../resources/parameters.json';
import { comuniCatanzaro } from '../resources/comuniCatanzaro.json';
import { comuniCosenza } from '../resources/comuniCosenza.json';
import { comuniCrotone } from '../resources/comuniCrotone.json';
import { comuniReggio } from '../resources/comuniReggio.json';
import { comuniVibo } from '../resources/comuniVibo.json';

export const distinctElementsByName = function(elements){
    var result = [];
    var map = new Map();
    for (var i in elements) {      
        if(!map.has(elements[i].name)){
            map.set(elements[i].name, true);
            result.push(elements[i]);
        }
    }
    return result;    
}

export const getDuration = function(seconds){
    return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export const formatDate = function(date) {

    // format the date
   // add leading zeroes to single-digit day/month/hours/minutes
   let d = date;
   d = [    
     '0' + (d.getMonth() + 1),
     '0' + d.getDate(),   
     '0' + d.getHours(),
     '0' + d.getMinutes()
   ].map(component => component.slice(-2)); // take last 2 digits of every component
 
   // join the components into date
   return {
        date : date.getFullYear()+'-'+d.slice(0, 2).join('-'),
        time : d.slice(2).join(':')
   }
   
 }

 export const formatDateTime = function(d) {
    return {
        date : d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
        time : d.getHours() + ':' + d.getMinutes()
    }
 }

 //restituisce la data 01/01/1970 con l'orario indicato passato con il formato HH:mm
 export const timeToDate = function(time) {
    let split = time.split(':');
    let d = new Date(1970, 0, 1, split[0], split[1], 0, 0);    
    return d;
 }

 export const stopTimeToTodayDate = function(time,departureTime,arrivalTime) {
  let now = new Date();  
  let stopTime = new Date();
  let departure = new Date();
  let arrival = new Date();


    let splittedDeparture = departureTime.split(':');
    departure.setHours(splittedDeparture[0], splittedDeparture[1], 0, 0);
    let splittedArrival = arrivalTime.split(':');
    arrival.setHours(splittedArrival[0], splittedArrival[1], 0, 0);


    let splitted = time.split(':');
    stopTime.setHours(splitted[0], splitted[1], 0, 0);
    if(departure.getTime()<=stopTime.getTime() && arrival.getTime()>=stopTime.getTime())
    return stopTime;
    if(departure.getTime()>arrival.getTime()){
      if(now.getTime()<=departure.getTime()){
        stopTime.setTime(stopTime.getTime()-24*3600*1000)
      }
      else stopTime.setTime(stopTime.getTime()+24*3600*1000)

    }
 }

 

 export const sumMinutes = function(date, minutes) {
    let d = new Date(date);
    return new Date(d.getTime() + (minutes*60000))
 }

 export const yyyyMMddToddMMyyyy = function(date) {
  let splitted = date.split('-');
  return splitted[2] + '/' + splitted[1] + '/' + splitted[0];
}

 export const timestampToDate = function(timestamp) {
    if(timestamp == null) return '';
    if(timestamp < 10000000000){
        timestamp *= 1000;
    }
    let d = new Date(timestamp);    
    return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() + " - " + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
 }

  export const travelsFilter = function(oraSelezionata, travels) {
     let oraMassima = sumMinutes(oraSelezionata, {maxMinutesRange}.maxMinutesRange); 
     let listaFiltrata = travels.filter(t => {
        let oraFermataPartenza = timeToDate(t.stops[0].time);          
         return oraSelezionata.getTime() <= oraFermataPartenza.getTime() && oraFermataPartenza.getTime() <= oraMassima.getTime();
       }
     );      
     return listaFiltrata;
 }

 export const findStopPacket = function(packets, durf) {
    //cerco nella lista il pacchetto corrispondente al durf della fermata passata
    for(let i = 0; i < packets.length; i++) {
      if(packets[i].packet.parameter && packets[i].packet.parameter == durf) {
        return packets[i].packet;
      }
    }
    return null;
  }

  export const getSelectedStop = function(travel, durf) {
    return travel.stops.filter(s => s.durfCode == durf)[0];
  }       

export const replaceAll = function (str, cerca, sostituisci) {
  return str.split(cerca).join(sostituisci);
}

export const operatorsArray =
  {
    'A.T.A.M - Azienda Trasporti per l\'Area Metropolitana S.p.A.':'atam.jpg',
    'Autolinee Brosio Nicola & F.lli ':'brosio.jpg', 
    'Autolinee Cav. Domenico Tripodi S.R.L.': 'tripodi.jpg',
    'Autolinee Federico S.P.A.' : 'federico.jpg',
    'Autoservizi Carnevale s.r.l.' : '',
    'Autoservizi Preite S.R.L' : 'preite.png',
    'Azienda Per La Mobilita\' Della Citta\' Di Catanzaro A.M.C.' : 'amc.png',  
    'Azienda Per La Mobilita\' Nell\'area Cosentina A.M.A.Co.' : 'amaco.jpg',  
    'Bilotta Antonio S.R.L.' : 'bilotta.png', 
    'Consorzio Autolinee TPL s.r.l.' : 'consorzio.PNG', 
    'Costa Viola Bus S.R.L.':'costaviola.png',
    'Ferloc S.R.L.':'ferloc.png',
    'Ferrovie Della Calabria S.R.L.':'ferrovie.png',
    'Fersav S.R.L.':'',
    'Flixbus Italia srl':'flixbus.jpg',
    'Francesco Perrone S.R.L.':'perrone.jpg',
    'G.B.V. S.R.L.':'',
    'Genco Carmela & Figli S.R.L.':'genco.jpg',
    'IAS Touring s.r.l.':'iastouring.jpg',
    'Impresa Autolinee Scura I.A.S. ': 'iasautolinee.png',
    'Impresa Individuale Zanfini Salvatore':'zanfini.PNG',
    'Lirosi Autoservizi S.R.L.':'lirosi.png',
    'Mediterraneabus S.P.A.':'mediterraneabus.PNG',
    'Multiservizi Lamezia Terme S.P.A.':'lameziamultiservizi.png',
    'P.P.M. - Piana Palmi Multiservizi S.P.A.':'pianapalmi.jpg',
    'Parise Rocco & Antonio':'parise.PNG',
    'Romano Autolinee Regionali ':'romano.jpg',
    'S.C.A.R. S.R.L.':'scar.jpg',
    'Servizi Automobilistici Jonici (S.A.J.) S.R.L.':'saj.jpg',
    'Societa\' Autolinee Tirreniche':'sat.jpg',
    'T.N.C. - Trasporti Nord Calabria S.R.L.':'tnc.png',
  }

;   

export const calcDistance = function(lat1, lon1, lat2, lon2, unit){
  if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    //debugger;
		return dist;
	}
}


export const isInHoursBeforeDeparture = (date, departureTimestamp, hours) => {
  let departure = departureTimestamp;
  // if(departureTimestamp>arrivalTimestamp)
  // departure -= 24*1000*60*60;

  var hourago= departure - (1000*60*60)*hours;
  return (date*1000) > hourago;
}
//TODO: capire perchè non salva sempre la distanza
export const getNearestDurfs = async(latitude,longitude,travel,distance)=>{
  var out = [];
  travel.stops.forEach(stop => {
    let calculatedDistance = calcDistance(latitude, longitude, stop.latitude, stop.longitude,"K")
    if(calculatedDistance<distance){
  if(stop.packetDistance == undefined || stop.packetDistance > calculatedDistance)
  stop["packetDistance"] = calculatedDistance;
  out.push(stop);

  }


  });
  //debugger;
  return out;


} 

//calcolo la distanza tra 2 coordinate
export const calcCrow = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // km
  var dLat = toRad(lat2-lat1);
  var dLon = toRad(lon2-lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
export const toRad = (Value) => {
    return Value * Math.PI / 180;
}

export const getComuniByProvincia = (provincia) => {
  let comuni = [];        
  if(provincia === 'CZ') {
      comuni = comuniCatanzaro;
  } else if(provincia === 'CS') {
      comuni = comuniCosenza;
  } else if(provincia === 'KR') {
      comuni = comuniCrotone;
  } else if(provincia === 'VV') {
      comuni = comuniVibo;
  } else if(provincia === 'RC') {
      comuni = comuniReggio;
  }

  return comuni;
}

export const isOraLegale = () => {
  return new Date().getTimezoneOffset() == -120;
}