import React from 'react';
import Topbar from '../../Utils/TopBar';
import Paper from '@material-ui/core/Paper';
import { TextField, Button, MenuItem, Breadcrumbs, Typography, Dialog,IconButton,AppBar,Toolbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { fetchFromOTP } from '../../Utils/network';
import { distinctElementsByName } from '../../Utils/utility';
import LoadingScreen from '../../Utils/LoadingScreen';
import Downshift from 'downshift';
import AccessTime from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider , KeyboardTimePicker,
  KeyboardDatePicker,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PianificaViaggioImg2 from '../../Images/PianificaViaggioImg2.jpg';
import Footer from '../../Utils/Footer';
import { Map, Marker, TileLayer, Tooltip, Polyline } from 'react-leaflet';
import L from 'leaflet';
import CookieComponent from '../../Utils/CookieComponent';
import {it} from 'date-fns/locale';


export default class PianificaViaggio extends React.Component {

  constructor() {
    super()
    this.state = {
      isLoading: false,
      stops: [],
      testoPartenza:'',
      testoArrivo:'',
      partenza:{},
      arrivo:{},
      dataPartenza:null,
      oraPartenza:null,
      disableDate:true,
      disableTime:true,
      disableButton:true,
      dialogPartenzaOpen:false,
      dialogArrivoOpen:false,
      startLatitude:null,
      startLongitude:null,
      endLatitude:null,
      endLongitude:null,
    }
    this.setPartenza=this.setPartenza.bind(this)
    this.setArrivo=this.setArrivo.bind(this)
  }

  componentDidMount() {
    this.loadStops();
  }



  setPartenza(event){
    let lat = event.latlng.lat
    let lon = event.latlng.lng
      this.setState({
        startLatitude: lat,
        startLongitude: lon,
        testoPartenza:'Punto di partenza ('+JSON.stringify(lat).substring(0,6)+" - "+ JSON.stringify(lon).substring(0,6)+")"
        })                  
    this.state.partenza.name="Punto specificato " +JSON.stringify(lat).substring(0,6)+ " - "+ JSON.stringify(lon).substring(0,6)
    this.state.partenza.lat=lat
    this.state.partenza.lon=lon

    if(this.state.testoArrivo!=''){
      this.setState({
        disableDate:false
      })
    }

  }

  setArrivo(event){
    let lat = event.latlng.lat
    let lon = event.latlng.lng
     this.setState({
        endLatitude: lat,
        endLongitude: lon,
        testoArrivo:'Punto di arrivo ('+JSON.stringify(lat).substring(0,6)+" - "+ JSON.stringify(lon).substring(0,6)+")"
       })
       
       
       this.state.arrivo.name="Punto specificato " +JSON.stringify(lat).substring(0,6)+ " - "+ JSON.stringify(lon).substring(0,6)
       this.state.arrivo.lat=lat
       this.state.arrivo.lon=lon

       if(this.state.testoPartenza!=''){
        this.setState({
          disableDate:false
        })
      }

  }


  loadStops() {
    this.setState({ isLoading: true });
    fetchFromOTP('index/stops')
      .then((res) => {
        this.setState({
          isLoading: false,
          stops: distinctElementsByName(res),
          error: res.error != undefined,
        });
      }
      );
  }

  renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
      style={{ marginLeft : window.innerWidth > 500 ? '25%' : 50, width:window.innerWidth > 500 ? '30%' : '70%',marginTop:15 }}
        onChange={(event) => {
          // this.setState({
          //   partenza: event.target.value
          // })
        }}

        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}

      />
    );
  }

  renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.name) > -1;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.name}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
          sposition:'absolute',top:0,left:0,zIndex:99,maxHeight:'50%'
        }}
      >
        {suggestion.name}
      </MenuItem>
    );
  }

  getSuggestions(value, { showEmpty = false } = {}) {
    let inputValue = value.trim().toLowerCase();
    let inputLength = inputValue.length;
    let count = 0;

    if (inputLength === 0 && !showEmpty) {
      return []
    }
    else {
      
      this.state.stops.filter(stop => {
        let keep =
          count < 5 && stop.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }
        return keep;
      })

    }
  }


  render() {
    const pointerIcon = new L.Icon({
      iconUrl: require('../../Images/leafletMarkers/marker-icon.png'),
      iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon.png'),
      iconAnchor: [12.5, 40],
      popupAnchor: [10, -44],
      iconSize: [25, 40],
      shadowUrl: 'leaflet/dist/images/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    })
    const myPointerIcon = new L.Icon({
      iconUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
      iconRetinaUrl: require('../../Images/leafletMarkers/marker-icon-green.png'),
      iconAnchor: [12.5, 40],
      popupAnchor: [10, -44],
      iconSize: [25, 40],
      shadowUrl: 'leaflet/dist/images/marker-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [20, 92],
    })
  const p1=L.latLng(  37.522050, 14.767147);
  const p2=L.latLng(40.505361, 17.903747);
  const mapBounds=L.latLngBounds(p1,p2);

    if (this.state.isLoading) {
      return (
        <div><LoadingScreen /></div>
      )
    }

    else {
      return (
    
        <div>
          <Topbar history={this.props.history} />
          <div style={{backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',height:'20vh',width:'100vw',backgroundImage:"url("+PianificaViaggioImg2+")",minHeight:320}}></div>
          <div></div>
          <br></br>
          <Paper elevation={0} >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Button onClick={()=>{this.props.history.push('/')}}>
                Home Page
              </Button>
              <Typography color="grey">PIANIFICA VIAGGIO</Typography>
            </Breadcrumbs>
          </Paper>
          <Downshift id="downshift-partenza"
          inputValue={this.state.testoPartenza}
          onInputValueChange={(event)=>{
            this.setState({
            testoPartenza:event
          })}}
          onChange={(selection) =>{
            this.setState({
              partenza:selection,
              testoPartenza:selection.name
            })

            if(this.state.testoArrivo!=''){
              this.setState({
                disableDate:false
              })
            }
            }
          }
          itemToString={item => (item ? item.name : '')}
          > 
            {({
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              selectedItem,
            }) => {
              const { onBlur, onFocus, ...inputProps } = getInputProps({
                placeholder: 'Digita indirizzo,via o città di partenza',
              });
              return (
                <span className={classes.container}>
                  {this.renderInput({
                    fullWidth: false,
                    classes,
                    label: 'Partenza',
                    InputLabelProps: getLabelProps({ shrink: true }),
                    InputProps: { onBlur, onFocus },
                    inputProps,
                  })}
                  <Button style={{ marginTop: window.innerWidth > 500 ? 35 : 20, marginLeft: 30,backgroundColor:'#31aa47' }} 
                  variant="contained" color="secondary"
                    onClick={() => {
                      this.setState({
                        dialogPartenzaOpen: true,
                      })
                    }}>
                    Seleziona su mappa
                  </Button>
                  <span style={{maxHeight:'50%',position:'abolute',top:'50%',zIndex:10}}{...getMenuProps()}>
                    {isOpen && inputValue.length>2? this.state.stops
                      .filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
                      .map((item, index) => (
                        <Paper
                          {...getItemProps({
                            key: item.name,
                            index,
                            item,
                            style: {
                              alignText:'center',
                              flexDirection:'row',
                              display:'flex',
                              cursor:'pointer',
                              backgroundColor:
                              highlightedIndex === index ? 'lightgray' : 'white',
                              fontWeight: selectedItem === item ? 'bold' : 'normal',
                              padding: 15,
                            },
                          })}
                        >
                          <span style={{marginLeft: window.innerWidth > 500 ?'35%':'10%'}}>{item.name}</span>
                        </Paper>
                      )                     
                      )
                      : null}
                  </span>
                </span>
              );
            }}
          </Downshift>
          
          <Downshift id="downshift-arrivo" 
          onInputValueChange={(event)=>{
            this.setState({
            testoArrivo:event
          })}}
          inputValue={this.state.testoArrivo}
          onChange={(selection) =>{
            this.setState({
              arrivo:selection,
              testoArrivo:selection.name
            })
            if(this.state.testoPartenza!=''){
              this.setState({
                disableDate:false
              })
            }
            }
          }
          itemToString={ item => ( item ? item.name : '' ) }
          >
            {({
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              selectedItem,
            }) => {
              const { onBlur, onFocus, ...inputProps } = getInputProps({
                placeholder: 'Digita indirizzo,via o città di arrivo',
              });
              return (
                <span className={classes.container} disabled={ true }>
                  {this.renderInput({
                    fullWidth: false,
                    classes,
                    label: 'Arrivo',
                    InputLabelProps: getLabelProps({ shrink: true }),
                    InputProps: { onBlur, onFocus },
                    inputProps,
                  })}
                  <Button style={{ marginTop: window.innerWidth > 500 ? 35 : 20, marginLeft: 35,backgroundColor:'#31aa47' }}
                    variant="contained" color="secondary"
                    onClick={() => {
                      this.setState({
                        dialogArrivoOpen: true,
                      })
                    }}
                  >
                    Seleziona su mappa
                  </Button>
                  <span  {...getMenuProps()}>
                    {isOpen && inputValue.length>2 ? this.state.stops
                      .filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
                      .map((item, index) => (
                        <Paper
                          {...getItemProps({
                            key: item.name,
                            index,
                            item,
                            style: {
                              alignText:'center',
                              flexDirection:'row',
                              display:'flex',
                              cursor:'pointer',
                              backgroundColor:
                              highlightedIndex === index ? 'lightgray' : 'white',
                              fontWeight: selectedItem === item ? 'bold' : 'normal',
                              padding: 15,
                            },
                          })}
                        >
                          <span style={{marginLeft: window.innerWidth > 500 ?'35%':'10%'}}>{item.name}</span>
                        </Paper>
                      ))
                      : null}
                      
                    <br></br>
                    <br></br>
                    <br></br>
                    <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
                      <KeyboardDatePicker
                        style={{ marginLeft:  window.innerWidth > 500 ? '25%' : 50, marginRight: 30 }}
                        format="dd/MM/yyyy"
                        id="data-viaggio"
                        label="Scegli la data"
                        disabled={this.state.disableDate}
                        value={this.state.dataPartenza}
                        onChange={()=>{console.log("")}}
                        onAccept={(date) => {
                          this.setState({
                            dataPartenza:date,
                            disableTime:false
                          })
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <KeyboardTimePicker
                        style={{ marginLeft: 50, marginRight: 40 }}
                        id="time-picker"
                        label="Inserisci l'orario"
                        ampm={false}
                        keyboardIcon={<AccessTime/>}
                        value={this.state.oraPartenza}
                        onChange={()=>{console.log("")}}
                        onAccept={(hour) => {
                          this.setState({
                            oraPartenza:hour,
                            disableButton:false
                          })
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        disabled={this.state.disableTime}
                      />
                    </MuiPickersUtilsProvider>
                    <Button variant="contained" color="primary"
                      style={{ marginTop: 15, marginLeft: 30 }}
                      onClick={(event) => {
                        this.props.history.push('/PianificaViaggio/ElencoItinerari', 
                        {Partenza : this.state.partenza, Arrivo : this.state.arrivo, DataPartenza : this.state.dataPartenza, OraPartenza : this.state.oraPartenza});
                      }}
                      disabled={this.state.disableButton}>
                      Calcola percorso
                    </Button>
                    </div>

                  </span>
                </span>
              );
            }}
          </Downshift>
          <br></br>
          <br></br>
          {/* DIALOGS PER LA SELEZIONE DELLA POSIZIONE */}
          <Dialog id='dialog-selezione-partenza' fullScreen open={this.state.dialogPartenzaOpen} >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography style={{ alignSelf: 'center', alignText: 'center' }} variant="h6" className={classes.title}>
                Scegli sulla mappa il punto da cui vuoi partire e clicca su OK
                </Typography>
                <Button variant="outlined" style={{marginLeft:20,color:'white'}} onClick={() => {
                  this.setState({ dialogPartenzaOpen: false })

                }} aria-label="close"
                >
                  OK
                </Button>
                <Button variant="outlined" style={{marginLeft:20,color:'white'}} onClick={() => {
                  this.setState({ dialogPartenzaOpen: false,
                    startLatitude: null,
                    startLongitude: null,
                    testoPartenza:'',
                    partenza:{},
                    })                  

                }} aria-label="close"
                >
                  Annulla
                </Button>
              </Toolbar>
            </AppBar>
            <br></br>
            <br></br>
            <br></br>
            <Map
              zoom={this.state.startLatitude!=null && this.state.startLongitude!=null? 13 : 9}
              style={{ height: "100vh",cursor:'pointer' }}
              center={[this.state.startLatitude ? this.state.startLatitude : 39.170338, this.state.startLongitude ? this.state.startLongitude : 16.530721]}
              onClick={(event) => {
                 this.setPartenza(event)
                    }}
                        maxBounds={mapBounds}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                        {this.state.startLatitude!=null && this.state.startLatitude!=null? 
                        <Marker icon={myPointerIcon} position={[this.state.startLatitude, this.state.startLongitude]} zIndexOffset={20}>
                            <Tooltip>Posizione di partenza</Tooltip>
                        </Marker>:null}
                        {this.state.endLatitude!=null && this.state.endLatitude!=null? 
                        <Marker icon={myPointerIcon} position={[this.state.endLatitude, this.state.endLongitude]} zIndexOffset={20}>
                            <Tooltip>Posizione di arrivo</Tooltip>
                        </Marker>:null}
                        {this.state.startLatitude!=null && this.state.startLatitude!=null && this.state.endLatitude!=null && this.state.endLatitude!=null? 
                        <Polyline
                          positions={[[this.state.startLatitude, this.state.startLongitude],[this.state.endLatitude, this.state.endLongitude]]}
                          color={"red"}
                        />:null}
                        
                    </Map>
            </Dialog>
            <Dialog id='dialog-selezione-arrivo' open={this.state.dialogArrivoOpen} >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography style={{ alignSelf: 'center', alignText: 'center' }} variant="h6" className={classes.title}>
                Scegli sulla mappa il punto dove vuoi arrivare e clicca su OK
                </Typography>
                <Button variant="outlined" style={{marginLeft:20,color:'white'}} onClick={() => {
                  this.setState({ dialogArrivoOpen: false })

                }} aria-label="close"
                >
                  OK
                </Button>
                <Button variant="outlined" style={{marginLeft:20,color:'white'}} onClick={() => {
                  this.setState({ dialogArrivoOpen: false,
                    endLatitude: null,
                    endLongitude: null,
                    testoArrivo:'',
                    arrivo:{},
                    })                  

                }} aria-label="close"
                >
                  Annulla
                </Button>
              </Toolbar>
            </AppBar>
            <br></br>
            <br></br>
            <br></br>
            <Map
              zoom={this.state.endLatitude!=null && this.state.endLongitude!=null? 13 : 9}
              style={{ height: "90vh",cursor:'pointer',margin:25 }}
              center={[this.state.endLatitude ? this.state.endLatitude : 39.170338, this.state.endLongitude ? this.state.endLongitude : 16.530721]}
              onClick={(event) => {
                    this.setArrivo(event)
                    }}
                    maxBounds={mapBounds}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                         {this.state.startLatitude!=null && this.state.startLatitude!=null? 
                        <Marker icon={myPointerIcon} position={[this.state.startLatitude, this.state.startLongitude]} zIndexOffset={20}>
                            <Tooltip>Posizione di partenza</Tooltip>
                        </Marker>:null}
                        {this.state.endLatitude!=null && this.state.endLatitude!=null? 
                        <Marker icon={myPointerIcon} position={[this.state.endLatitude, this.state.endLongitude]} zIndexOffset={20}>
                            <Tooltip>Posizione di arrivo</Tooltip>
                        </Marker>:null}
                        {this.state.startLatitude!=null && this.state.startLatitude!=null && this.state.endLatitude!=null && this.state.endLatitude!=null? 
                        <Polyline
                          positions={[[this.state.startLatitude, this.state.startLongitude],[this.state.endLatitude, this.state.endLongitude]]}
                          color={"red"}
                        />:null}
                        
                    </Map>
            </Dialog>
            
            <CookieComponent/>
                
          <Footer/>
        </div>


      )
    }
  }
}

const classes = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  title: {
    marginLeft: 10,
    flex: 1,
  },
}));