import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import {Typography , Dialog , DialogActions , DialogTitle , DialogContent, IconButton, Button} from '@material-ui/core';
import OperatorImg2 from './Images/OperatorImg2.png'
import LineeImg from './Images/LineeImg.jpg';
import FermateImg from './Images/FermateImg.jpg'
import PianificaViaggioImg2 from './Images/PianificaViaggioImg2.jpg';
import NewsImg from './Images/NewsImg.jpg';
import InformazioniImg from './Images/InformazioniImg.jpg';
import CloseIcon from '@material-ui/icons/Close'; 
import operator from './Images/15.png'
import lines from './Images/14.png'
import stops from './Images/4.png'
import tripplan from './Images/3.png'
import news from './Images/5.png'
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import help from './Images/help.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '50vw',
    height:'50vh',
  },
  image: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {

    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.7,
      },
      '& $imageMarked': {
        opacity: 0.7,
      },
      '& $imageTitle': {
        color: '#bfd253',
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection:'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    color: '#ffffff',
    textShadow:'2px 2px #090F16',
    
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center 40%',
    backgroundRepeat:'no-repeat',
    
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.2,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    fontSize:26,
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  
}));

export default function ButtonBases(props) {

  const [addDialogOpen, setDialogOpen] = React.useState(false);

  const handleAddClick = () => {
    setDialogOpen(true);
  };
  const handleClose = ()=>{
    setDialogOpen(false)
  }

  const classes = useStyles();
  const buttonStyles={
    buttons:{
        width: '44vw',
         height:window.innerWidth>=500?'28vh':'18vh',
         margin:'2%'
    }
  }
  return (
    <div class="homeButtons" style={{flexDirection:'row',alignContent:'center',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
    <div>
      <span className={classes.root}>
          {/* <ButtonBase
            focusRipple
            key={"operatori"}
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={buttonStyles.buttons}
            onClick={()=>{
              props.history.push('/Operatori/OperatoriElenco')
            }}
          > */}
          <ButtonBase style={{flexDirection:'column',width:'45vw'}}
          onClick={()=>{
            props.history.push('/Operatori/OperatoriElenco')
          }}>
            <img class="buttonBase homeIcon" src={operator}/><Typography variant="h5">Operatori</Typography>
          </ButtonBase>
            {/* <span
              className={classes.imageSrc}
              style={{
                backgroundImage: 'url('+operator+')',
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                {"Operatori"}
                <span className={classes.imageMarked} />
              </Typography>
            </span> */}
          {/* </ButtonBase> */}
          <ButtonBase style={{flexDirection:'column',width:'45vw'}}
          onClick={()=>{
            props.history.push('/Linee/LineeElenco')
          }}><img class="buttonBase homeIcon" src={lines}/><Typography variant="h5">Linee</Typography></ButtonBase>
          {/* <ButtonBase
            focusRipple
            key={"linee"}
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={buttonStyles.buttons}
            onClick={()=>{
              props.history.push('/Linee/LineeElenco')
            }}
          >
            <span
              className={classes.imageSrc}
              style={{
                backgroundImage: 'url('+LineeImg+')',
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                {"Linee"}
                <span className={classes.imageMarked} />
              </Typography>
            </span>
          </ButtonBase> */}
        </span>
        </div>
        <div>
          <span className={classes.root}>
          <ButtonBase style={{flexDirection:'column',width:'45vw'}}
          onClick={handleAddClick}><img class="buttonBase homeIcon" src={stops}/><Typography variant="h5">Fermate</Typography></ButtonBase>
              {/* <ButtonBase
                focusRipple
                key={"fermate"}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={buttonStyles.buttons}
                onClick={()=>{
                  setDialogOpen(true)
                }}
              >
                <span
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: 'url('+FermateImg+')',
                  }}
                />
                <span className={classes.imageBackdrop} />
                <span className={classes.imageButton}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                    onClick={handleAddClick}
                  >
                    {"Fermate"}
                    <span className={classes.imageMarked} />
                  </Typography>
                </span>
              </ButtonBase>
              <ButtonBase
                focusRipple
                key={"pianificaviaggio"}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={buttonStyles.buttons}
                onClick={()=>{
                  props.history.push('/PianificaViaggio/PianificaViaggio')
                }}
              >
                <span
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: 'url('+PianificaViaggioImg2+')',
                  }}
                />
                <span className={classes.imageButton}>
                <span className={classes.imageBackdrop} />
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                  >
                    {"Pianifica viaggio"}
                    <span className={classes.imageMarked} />
                  </Typography>
                </span>
              </ButtonBase> */}
          <ButtonBase style={{flexDirection:'column',width:'45vw'}}
            onClick={()=>{
              props.history.push('/PianificaViaggio/PianificaViaggio')
            }}><img class="buttonBase homeIcon" src={tripplan}/><Typography variant="h5">Pianifica Viaggio</Typography>
          </ButtonBase>
            </span>
        </div>
        <div>
          <span className={classes.root}>
          <ButtonBase style={{flexDirection:'column',width:'45vw'}}
            onClick={()=>{
              props.history.push('/Notizie/ListaNews')
            }}><img class="buttonBase homeIcon" src={news}/><Typography variant="h5">News</Typography>
          </ButtonBase>
          <ButtonBase style={{flexDirection:'column',width:'45vw'}}
            onClick={()=>{
              props.history.push('/Informazioni/Informazioni')
            }}><img class="buttonBase homeIcon" src={help}/><Typography variant="h5">Info</Typography>
          </ButtonBase>
              {/* <ButtonBase
                focusRipple
                key={"avvisi"}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={buttonStyles.buttons}
                onClick={()=>{
                  props.history.push('/Notizie/ListaAvvisi')
                }}
              >
                <span
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: 'url('+NewsImg+')',
                  }}
                />
                <span className={classes.imageBackdrop} />
                <span className={classes.imageButton}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                    
                  >
                    {"Avvisi"}
                    <span className={classes.imageMarked} />
                  </Typography>
                </span>
              </ButtonBase>
              <ButtonBase
                focusRipple
                key={"info"}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={buttonStyles.buttons}
                onClick={()=>{
                  props.history.push('/Informazioni/Informazioni')
                }}
              >
                <span
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: 'url('+InformazioniImg+')',
                  }}
                />
                <span className={classes.imageButton}>
                <span className={classes.imageBackdrop} />
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                  >
                    {"Informazioni"}
                    <span className={classes.imageMarked} />
                  </Typography>
                </span>
              </ButtonBase> */}
            </span>
        </div>
          <Dialog disableBackdropClick={true} onClose={handleClose} aria-labelledby="customized-dialog-title" open={addDialogOpen}>
            <DialogTitle id="customized-dialog-title">
              Ricerca delle fermate
            <IconButton aria-label="close" edge={"end"} onClick={() => {setDialogOpen(false)}}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Da questo menu puoi scegliere se :<br></br>
                - visionare l'elenco completo di tutte le fermate della regione Calabria,
                                              con possibilità di ricerca tramite testo.<br></br>
                - visualizzare tutte le fermate nelle vicinanze del tuo dispositivo.<br></br>
                - effettuare una ricerca delle fermate vicine ad una determinata zona
                  tramite click sulla mappa.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button 
              onClick={() => {
                setDialogOpen(false)
                props.history.push('/Fermate/FermateElenco')
              }}
                color="primary"
                variant="contained">
                Elenco completo fermate
              </Button>
              <Button onClick={() => {
                setDialogOpen(false)
                props.history.push('/Fermate/FermateVicine')
              }}
                color="primary"
                variant="contained">
                Fermate vicine
              </Button>
              <Button onClick={() => {
                setDialogOpen(false)
                props.history.push('/Fermate/FermateRicerca')
              }}
                color="primary"
                variant="contained">
                Cerca sulla mappa
              </Button>
            </DialogActions>
          </Dialog>
    </div>
  );
}