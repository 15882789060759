
import React from 'react';
import CoreBaseClass from './CoreBaseClass';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logoblu from '../Images/logoblu.png';
import Drawer from '@material-ui/core/Drawer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import MenuLaterale from './MenuLaterale';

export default class Topbar extends CoreBaseClass {
    
    constructor(){
        super();
        this.state = {
            isLoading:true,
            error:'',
            input:'',
            open:false,            
            windowSize: window.innerWidth,
        };
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
        this.handleDrawerClose = this.handleDrawerClose.bind(this)
    }

     componentDidMount(){
        window.addEventListener('resize', this.handleResize);
        document.addEventListener('mousedown', this.handleClickOutside);
     }

     componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        document.removeEventListener('mousedown', this.handleClickOutside);
    } 

    handleResize = e => {
        const windowSize = window.innerWidth;
        this.setState(prevState => {
          return {
            windowSize,
          };
        });
      };
    
     handleDrawerOpen() {
        this.setState({
            open:true
        });
      }
    
      handleDrawerClose() {
        this.setState({
            open:false
        });
      }

    render(){

        return(
            
            <div>                
                {this.getDialog()}
                <AppBar position="static" > 
                <Toolbar style={{justifyContent:'space-evenly',alignContent:'space-between'}}>
                    <img src={logoblu} style={{height:'5%', width:'5%',padding:1,minWidth:40, minHeight:40,maxWidth:55,maxHeight:55}} />
                    <div><h2 style={{margin:1,lineHeight:1}}><b>CORE</b></h2>
                    <h3 style={{margin:1,lineHeight:1}}><b>C</b>entrale <b>O</b>perativa <b>RE</b>gionale</h3>
                    </div>
                        {
                            this.state.windowSize < 992 ? null :
                                window.routes.map(r =>                                     
                                    <Button color="inherit" style={{fontSize:16}} onClick={() => {
                                            if(r.title.includes('Fermate'))                                             
                                                this.handleDialogOpen();
                                            else
                                                this.navigate(r.title);
                                        }
                                    }>{r.title}</Button>                                    
                                )
                        }                                            
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={this.handleDrawerOpen}
                           >
                            <MenuIcon />
                        </IconButton>   
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={this.state.open}
                >
                    <div style={{backgroundColor:'#3f51b5'}}>
                        <IconButton onClick={this.handleDrawerClose} style={{color:'white'}}>
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    
                    <Divider />
                        <MenuLaterale  history={this.props.history} dialogOpen={this.state.dialogOpen}/>
                    <Divider />
                </Drawer>               
            </div>
            )
        }
    }

   

